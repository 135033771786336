import styled from "styled-components";

export const ContextActionsContainerStyled = styled.div`
    justify-content: flex-end;
    padding-right: 54px;
    position: fixed;
    right: 0;
    top: 87px;
    z-index: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
`;

export const ContextActionsButtonStyled = styled.button`
    background-color: white;
    border-radius: 7px;
    display: flex;
    align-items: center;
    padding: 7px 12px;
    border: none;
    white-space: nowrap;
    gap: 10px;
    width: fit-content;
    cursor: pointer;
    transition: all 0.3s ease;
`;

export const ContextActionsInputStyled = styled.input`
    background-color: white;
    border-radius: 7px;
    padding: 9.5px;
    border: none;
    outline: none;
`;

export const ContextActionsOptionsContainer = styled.div`
    background-color: white;
    border: 1px solid #ececec;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    position: absolute;
    top: 110%;
    display: flex;
    flex-direction: column;
    ${(props: { isHidden: boolean }) =>
        props.isHidden
            ? `    opacity: 0;
    visibility: hidden;
    z-index: -1;`
            : ""}

    button {
    background: none;
    border: none;
    padding:15px;
    display: flex;
    cursor:pointer;
    gap: 5px;
    align-items: center;
    color: #262626d4;
    width: '-moz-fit-content',
    width: 'fit-content',
    cursor: 'pointer',
    transition: 'all 0.3s ease'
    fontFamily: '"fuente-regular"',
}
    }
`;
