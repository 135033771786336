import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const facilitatorsApi = createApi({
    reducerPath: "facilitatorsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["Facilitators"],
    endpoints: (builder) => ({
        getFacilitators: builder.query({
            query: ({ page, rowsCount, q }) =>
                `facilitadores?page=${page}&rowsCount=${rowsCount}&filters[Nombres][$contains]=${q}`,
            providesTags: ["Facilitators"],
        }),
        getFacilitatorById: builder.query({
            query: (id) => `facilitadores/${id}`,
            providesTags: ["Facilitators"],
        }),
        getDetailFacilitator: builder.mutation({
            query: (id: number) => ({
                url: "/facilitadores/getDetalle",
                method: "POST",
                body: { FacilitadorId: id },
            }),
            invalidatesTags: ["Facilitators"],
        }),
        getFacilitadoresGetWithFkNames: builder.mutation({
            query: (q: string) => ({
                url: "/facilitadores/GetWithFkNames",
                method: "POST",
                body: { q },
            }),
            invalidatesTags: ["Facilitators"],
        }),
        createFacilitator: builder.mutation({
            query: (newFacilitator) => ({
                url: "/Facilitadores",
                method: "POST",
                body: newFacilitator,
            }),
            invalidatesTags: ["Facilitators"],
        }),
        updateFacilitator: builder.mutation({
            query: (updateFacilitator) => ({
                url: `/Facilitadores/${updateFacilitator.FacilitadorId}`,
                method: "PUT",
                body: updateFacilitator,
            }),
            invalidatesTags: ["Facilitators"],
        }),
        deleteFacilitator: builder.mutation({
            query: (newFacilitator) => ({
                url: `/Facilitadores/${newFacilitator}`,
                method: "POST",
                body: newFacilitator,
            }),
            invalidatesTags: ["Facilitators"],
        }),
        getFacilitatorNames: builder.mutation({
            query: () => ({
                url: "/Facilitadores/GetNombres",
                method: "POST",
            }),
            invalidatesTags: ["Facilitators"],
        }),
        verificarCodigoFacilitador: builder.mutation({
            query: (codigo: string) => ({
                url: `/Facilitadores/VerificarCodigoFacilitador`,
                method: "POST",
                body: { Codigo: codigo },
            }),
            invalidatesTags: ["Facilitators"],
        }),
    }),
});

export const {
    useGetFacilitatorsQuery,
    useGetFacilitatorByIdQuery,
    useGetFacilitadoresGetWithFkNamesMutation,
    useGetDetailFacilitatorMutation,
    useCreateFacilitatorMutation,
    useUpdateFacilitatorMutation,
    useDeleteFacilitatorMutation,
    useGetFacilitatorNamesMutation,
    useVerificarCodigoFacilitadorMutation,
} = facilitatorsApi;
