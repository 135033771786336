import styled from "styled-components";
import { Heading, Input, Text, Card as BaseCard, Flex } from "@nubeteck/components";

import { devices } from "../../constants";

export const HeadingStyled = styled(Heading)`
    color: ${({ theme }) => theme.colors.loginHeading} !important;
    margin-bottom: 40px !important;
`;

export const TextStyled = styled(Text)`
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.primary900};
`;

export const InputStyled = styled(Input)`
    margin-top: 8px;
    border: 1px solid ${({ theme }) => theme.colors.inputBorder};
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
`;

export const ProfileConfigContainer = styled(Flex)`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 64px;
    padding-top: 32px;
    height: 100%;

    @media ${devices.lg} {
        flex-direction: row;
        align-items: flex-start;
        column-gap: 96px;
    }
`;

export const ProfileBaseCard = styled(BaseCard)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 400px;
    min-width: 250px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    gap: 10px;

    @media ${devices.lg} {
        width: 50%;
    }
`;

export const TextProfile = styled(Text)``;

export const ProfileCardDivRoles = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(163, 165, 169, 0.5);
    border-radius: 5px;
    margin-bottom: 96px;

    ${TextProfile} {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.primary900};
        font-weight: 700;
    }

    @media ${devices.lg} {
        padding: 32px 64px;
    }
`;

export const ProfileFormForm = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-bottom: 64px;

    @media ${devices.lg} {
        margin-bottom: 0;
    }
`;

export const ProfileFormDivRow1 = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    row-gap: 20px;

    @media ${devices.lg} {
        flex-direction: row;
        column-gap: 24px;
    }
`;

export const ProfileFormDivRow5 = styled.div`
    display: flex;
    justify-content: flex-end;
    column-gap: 24px;
`;
