import { createSlice } from "@reduxjs/toolkit";
interface CapacitationStudents {
    capacitationStudents: ICapacitationStudentState;
}
interface ICapacitationStudentState {
    capacitationStudents: [];
}

export const capacitationStudentsSlice = createSlice({
    name: "capacitationStudents",
    initialState: {
        capacitationStudents: [],
        nationality: {},
    },
    reducers: {
        setCapacitationStudents: (state, action) => {
            state.capacitationStudents = action.payload;
        },
        setCapacitationStudent: (state, action) => {
            state.nationality = action.payload;
        },
    },
});

export const { setCapacitationStudents } = capacitationStudentsSlice.actions;

export const selectCapacitationStudents = (state: CapacitationStudents) =>
    state.capacitationStudents.capacitationStudents;

export default capacitationStudentsSlice.reducer;
