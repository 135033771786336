import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const enclosuresApi = createApi({
    reducerPath: "enclosuresApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["Recintos"],
    endpoints: (builder) => ({
        getEnclosures: builder.query({
            query: () => `Recintos`,
            providesTags: ["Recintos"],
        }),
    }),
});

export const { useGetEnclosuresQuery } = enclosuresApi;
