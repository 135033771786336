import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const schoolsApi = createApi({
    reducerPath: "schoolsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["Schools"],
    endpoints: (builder) => ({
        getSchools: builder.query({
            query: () => `Escuelas`,
            providesTags: ["Schools"],
        }),
        getSchoolsByFaculties: builder.mutation({
            query: (facultadesIds: string) => ({
                url: "/Escuelas/GetByFacultades",
                method: "POST",
                body: { facultadesIds: facultadesIds },
            }),
            invalidatesTags: ["Schools"],
        }),
    }),
});

export const { useGetSchoolsQuery, useGetSchoolsByFacultiesMutation } = schoolsApi;
