import { setupListeners } from "@reduxjs/toolkit/query/react";
import { configureStore } from "@reduxjs/toolkit";

import { authApi, usersApi } from "@api";
import auth from "./slices/authSlice";
import users from "./slices/usersSlice";
import facilitators from "./slices/facilitatorsSlice";
import nationalities from "./slices/nationalitiesSlice";
import capacitationTypes from "./slices/capacitationTypesSlice";
import certificateTypes from "./slices/certificateTypesSlice";
import evaluationMethods from "./slices/evaluationMethodsSlice";
import evaluationRubros from "./slices/evaluationRubrosSlice";
import genres from "./slices/genresSlice";
import roles from "./slices/rolesSlice";
import enclosures from "./slices/rolesSlice";
import periods from "./slices/periodsSlice";
import rubros from "./slices/rubrosSlice";
import capacitations from "./slices/capacitationsSlice";
import sesionCapacitations from "./slices/sesionCapacitationsSlice";
import logs from "./slices/logsSlice";
import teachers from "./slices/teacherSlice";
import scores from "./slices/scoresSlice";
import capacitationStudents from "./slices/capacitationStudentsSlice";
import studentsScores from "./slices/studentsScoresSlice";
import academicLevels from "./slices/academicLevelsSlice";
import identityDocumentsTypes from "./slices/academicLevelsSlice";
import plantillas from "./slices/plantillasSlice";
import capacitationModules from "./slices/capacitationModulesSlice";
import capacitationModulesCapacitations from "./slices/capacitationModulesCapacitationsSlice";
//--
import enrollPrincipalCapacitations from "./slices/enrollPrincipalCapacitationsSlice";
import { facilitatorsApi } from "src/api/facilitatorsApi";
import { nationalitiesApi } from "src/api/nationalitiesApi";
import { capacitationTypesApi } from "src/api/capacitationTypesApi";
import { certificateTypesApi } from "src/api/certificateTypesApi";
import { evaluationMethodsApi } from "src/api/evaluationMethodsApi";
import { evaluationRubrosApi } from "src/api/evaluationRubrosApi";
import { genresApi } from "src/api/genresApi";
import { periodsApi } from "src/api/periodsApi";
import { rubrosApi } from "src/api/rubrosApi";
import { capacitationsApi } from "src/api/capacitationsApi";
import { sesionCapacitationsApi } from "src/api/sesionCapacitationsApi";
import { logsApi } from "src/api/logsApi";
import { teachersApi } from "src/api/teacherApi";
import { capacitationStudentsApi } from "src/api/capacitationStudentsApi";
import { scoresApi } from "src/api/scoresApi";
import { studentsScoresApi } from "src/api/studentsScores";
import { formsCalculationApi } from "src/api/formsCalculationApi";
import { academicLevelsApi } from "src/api/academicLevelsApi";
import { identityDocumentsTypesApi } from "src/api/identityDocumentsTypesApi";
import { rolesApi } from "src/api/rolesApi";
import { enclosuresApi } from "src/api/enclosuresApi";
import { facultiesApi } from "src/api/facultiesApi";
import { capacitationModulesApi } from "src/api/capacitationModulesApi";
import facultiesSlice from "./slices/facultiesSlice";
import schoolsSlice from "./slices/schoolsSlice";
import { schoolsApi } from "src/api/schoolsApi";
import teacherSourceSlice from "./slices/teacherSourceSlice";
import { teachersSourceApi } from "src/api/teacherSourceApi";
import { plantillasApi } from "src/api/plantillasApi";
import sesionCapacitationTypesSlice from "./slices/sesionCapacitationTypesSlice";
import { sessionCapacitationTypesApi } from "src/api/sesionCapacitationTypesApi";
import { capacitationModulesCapacitationsApi } from "../../api/capacitationModulesCapacitations";
import { dashboardCapacitationsApi } from "src/api/dashboardCapacitationsApi";
import certySafeSlice from "./slices/certySafeSlice";
import { certySafeApi } from "../../api/certysafeApi";
import generalSettingsSlice from "./slices/generalSettingsSlice";
import { generalSettingsApi } from "src/api/generalSettingsApi";
import appIdsSlice from "./slices/appIdSlice";
import { appIdsApi } from "src/api/appIdApi";

import selectionsPeriodsSlice from "./slices/selectionsPeriodsSlice";
import { selectionsPeriodsApi } from "src/api/selectionsPeriodsApi";

import capacitacionesPrincipalesOficializacionesSeleccionesSlice from "./slices/capacitacionesPrincipalesOficializacionesSeleccionesSlice";
import { capacitacionesPrincipalesOficializacionesSeleccionesApi } from "src/api/capacitacionesPrincipalesOficializacionesSeleccinesApi";

import { enrollPrincipalCapacitationsApi } from "src/api/enrollPrincipalCapacitations.Api";
import certificatesSlice from "./slices/certificatesSlice";
import { certificatesApi } from "src/api/certificatesApi";

export const store = configureStore({
    reducer: {
        auth,
        users,
        facilitators,
        nationalities,
        capacitationTypes,
        certificateTypes,
        evaluationMethods,
        evaluationRubros,
        genres,
        roles,
        enclosures,
        periods,
        rubros,
        capacitations,
        sesionCapacitations,
        logs,
        teachers,
        capacitationStudents,
        scores,
        studentsScores,
        academicLevels,
        identityDocumentsTypes,
        plantillas,
        faculties: facultiesSlice,
        schools: schoolsSlice,
        teachersSource: teacherSourceSlice,
        sessionCapacitationTypes: sesionCapacitationTypesSlice,
        capacitationModules,
        capacitationModulesCapacitations,
        certySafeSlice,
        generalSettingsSlice,
        appIdsSlice,
        enrollPrincipalCapacitations,
        certificatesSlice,
        selectionsPeriodsSlice,
        capacitacionesPrincipalesOficializacionesSeleccionesSlice,
        [scoresApi.reducerPath]: scoresApi.reducer,
        [certySafeApi.reducerPath]: certySafeApi.reducer,
        [studentsScoresApi.reducerPath]: studentsScoresApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        [facilitatorsApi.reducerPath]: facilitatorsApi.reducer,
        [nationalitiesApi.reducerPath]: nationalitiesApi.reducer,
        [capacitationTypesApi.reducerPath]: capacitationTypesApi.reducer,
        [certificateTypesApi.reducerPath]: certificateTypesApi.reducer,
        [evaluationMethodsApi.reducerPath]: evaluationMethodsApi.reducer,
        [evaluationRubrosApi.reducerPath]: evaluationRubrosApi.reducer,
        [genresApi.reducerPath]: genresApi.reducer,
        [rolesApi.reducerPath]: rolesApi.reducer,
        [enclosuresApi.reducerPath]: enclosuresApi.reducer,
        [periodsApi.reducerPath]: periodsApi.reducer,
        [rubrosApi.reducerPath]: rubrosApi.reducer,
        [capacitationsApi.reducerPath]: capacitationsApi.reducer,
        [sesionCapacitationsApi.reducerPath]: sesionCapacitationsApi.reducer,
        [logsApi.reducerPath]: logsApi.reducer,
        [teachersApi.reducerPath]: teachersApi.reducer,
        [capacitationStudentsApi.reducerPath]: capacitationStudentsApi.reducer,
        [formsCalculationApi.reducerPath]: formsCalculationApi.reducer,
        [academicLevelsApi.reducerPath]: academicLevelsApi.reducer,
        [identityDocumentsTypesApi.reducerPath]: identityDocumentsTypesApi.reducer,
        [facultiesApi.reducerPath]: facultiesApi.reducer,
        [schoolsApi.reducerPath]: schoolsApi.reducer,
        [teachersSourceApi.reducerPath]: teachersSourceApi.reducer,
        [plantillasApi.reducerPath]: plantillasApi.reducer,
        [sessionCapacitationTypesApi.reducerPath]: sessionCapacitationTypesApi.reducer,
        [capacitationModulesApi.reducerPath]: capacitationModulesApi.reducer,
        [capacitationModulesCapacitationsApi.reducerPath]:
            capacitationModulesCapacitationsApi.reducer,
        [dashboardCapacitationsApi.reducerPath]: dashboardCapacitationsApi.reducer,
        [generalSettingsApi.reducerPath]: generalSettingsApi.reducer,
        [appIdsApi.reducerPath]: appIdsApi.reducer,
        [certificatesApi.reducerPath]: certificatesApi.reducer,
        [enrollPrincipalCapacitationsApi.reducerPath]: enrollPrincipalCapacitationsApi.reducer,
        [selectionsPeriodsApi.reducerPath]: selectionsPeriodsApi.reducer,
        //--
        [capacitacionesPrincipalesOficializacionesSeleccionesApi.reducerPath]:
            capacitacionesPrincipalesOficializacionesSeleccionesApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            authApi.middleware,
            usersApi.middleware,
            facilitatorsApi.middleware,
            nationalitiesApi.middleware,
            capacitationTypesApi.middleware,
            certificateTypesApi.middleware,
            evaluationMethodsApi.middleware,
            evaluationRubrosApi.middleware,
            genresApi.middleware,
            rolesApi.middleware,
            enclosuresApi.middleware,
            periodsApi.middleware,
            rubrosApi.middleware,
            capacitationsApi.middleware,
            sesionCapacitationsApi.middleware,
            logsApi.middleware,
            teachersApi.middleware,
            capacitationStudentsApi.middleware,
            scoresApi.middleware,
            studentsScoresApi.middleware,
            formsCalculationApi.middleware,
            academicLevelsApi.middleware,
            identityDocumentsTypesApi.middleware,
            facultiesApi.middleware,
            schoolsApi.middleware,
            teachersSourceApi.middleware,
            plantillasApi.middleware,
            sessionCapacitationTypesApi.middleware,
            dashboardCapacitationsApi.middleware,
            capacitationModulesApi.middleware,
            capacitationModulesCapacitationsApi.middleware,
            certySafeApi.middleware,
            generalSettingsApi.middleware,
            appIdsApi.middleware,
            enrollPrincipalCapacitationsApi.middleware,
            certificatesApi.middleware,
            selectionsPeriodsApi.middleware,
            capacitacionesPrincipalesOficializacionesSeleccionesApi.middleware,
        ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
setupListeners(store.dispatch);
