import { createSlice } from "@reduxjs/toolkit";
interface GeneralSettings {
    generalSettings: IGeneralSettingState;
}
interface IGeneralSettingState {
    generalSettings: [];
}

export const generalSettingsSlice = createSlice({
    name: "generalSettings",
    initialState: {
        generalSettings: [
            {
                Seccion: "",
                Configuraciones: [
                    { Id: 0, Rules: "", ValorPorDefecto: 0, Nombre: "", Valor: "", Label: "" },
                ],
            },
        ],
    },
    reducers: {
        setGeneralSettings: (state, action) => {
            state.generalSettings = action.payload;
        },
    },
});

export const { setGeneralSettings } = generalSettingsSlice.actions;

export const selectGeneralSettings = (state: GeneralSettings) =>
    state.generalSettings.generalSettings;

export default generalSettingsSlice.reducer;
