import { createSlice } from "@reduxjs/toolkit";
interface CapacitacionesPrincipalesOficializacionesSelecciones {
    CapacitacionesPrincipalesOficializacionesSelecciones: CapacitacionesPrincipalesOficializacionesSeleccionesState;
}
interface CapacitacionesPrincipalesOficializacionesSeleccionesState {
    CapacitacionesPrincipalesOficializacionesSelecciones: [];
}

export const capacitacionesPrincipalesOficializacionesSeleccionesSlice = createSlice({
    name: "CapacitacionesPrincipalesOficializacionesSelecciones",
    initialState: {
        CapacitacionesPrincipalesOficializacionesSelecciones: [],
    },
    reducers: {
        setCapacitacionesPrincipalesOficializacionesSelecciones: (state, action) => {
            state.CapacitacionesPrincipalesOficializacionesSelecciones = action.payload;
        },
    },
});

export const { setCapacitacionesPrincipalesOficializacionesSelecciones } =
    capacitacionesPrincipalesOficializacionesSeleccionesSlice.actions;

export const selectCapacitationModules = (
    state: CapacitacionesPrincipalesOficializacionesSelecciones,
) =>
    state.CapacitacionesPrincipalesOficializacionesSelecciones
        .CapacitacionesPrincipalesOficializacionesSelecciones;

export default capacitacionesPrincipalesOficializacionesSeleccionesSlice.reducer;
