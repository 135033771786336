import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const genresApi = createApi({
    reducerPath: "genresApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["Genres"],
    endpoints: (builder) => ({
        getGenres: builder.query({
            query: () => `Generos`,
            providesTags: ["Genres"],
        }),
    }),
});

export const { useGetGenresQuery } = genresApi;
