import React from "react";
import { Card, ProfileAvatar } from "@nubeteck/components";

import { profilepic1 } from "@assets";
import {
    ProfileConfigContainer,
    ProfileCardDivRoles,
    ProfileBaseCard,
    TextProfile,
} from "./profile-config.styled";
import { message } from "antd";
import { useGetUserByIdMutation } from "@api";

const ProfileConfig = () => {
    const [getUserById, { data, isLoading, isError }] = useGetUserByIdMutation();
    React.useEffect(() => {
        if (isError) {
            message.error("Error al intentar buscar los datos del usuario");
        }
    }, [isError]);

    React.useEffect(() => {
        getUserById("");
    }, [getUserById]);

    return (
        <ProfileConfigContainer>
            <ProfileBaseCard size="default">
                <ProfileAvatar
                    image={profilepic1}
                    name={data?.Result[0].FullName}
                    email={data?.Result[0].Email}
                    alt="Profile pic"
                />
                <ProfileCardDivRoles>
                    <TextProfile>Roles de usuario:</TextProfile>
                    <TextProfile>
                        {data?.Result[0].Roles.map(
                            (x: { RolId: number; RolName: string }) => x.RolName,
                        ).join(",")}
                    </TextProfile>
                </ProfileCardDivRoles>
            </ProfileBaseCard>
            <Card
                style={{ width: "45%", boxShadow: "0.5px 0.5px 3px rgba(0, 0, 0, 0.15)" }}
                loading={isLoading}
                title={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <h3>Información adicional </h3>
                    </div>
                }
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                    }}
                >
                    <span style={{ width: "50%", marginBottom: "10px" }}>
                        <strong>Número telefónico: </strong>
                        <br />
                        {/* {dataInfoBasic?.Result[0]?.Nombres} */}
                    </span>
                    <span style={{ width: "50%", marginBottom: "10px" }}>
                        <strong>Dirección: </strong>
                        <br />
                        {/* {`${dataInfoBasic?.Result[0]?.Apellido1} ${dataInfoBasic?.Result[0]?.Apellido2}`} */}
                    </span>
                </div>
            </Card>
        </ProfileConfigContainer>
    );
};

export default ProfileConfig;
