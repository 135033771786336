import { createSlice } from "@reduxjs/toolkit";
interface Genres {
    genres: IGenreState;
}
interface IGenreState {
    genres: [];
}

export const genresSlice = createSlice({
    name: "genres",
    initialState: {
        genres: [],
    },
    reducers: {
        setGenres: (state, action) => {
            state.genres = action.payload;
        },
    },
});

export const { setGenres } = genresSlice.actions;

export const selectGenres = (state: Genres) => state.genres.genres;

export default genresSlice.reducer;
