import styled from "styled-components";

export const NoResultContainer = styled.div`
    width: 100%;
    padding: 4% 20%;
    box-sizing: border-box;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 20px;
    img {
        width: 50%;
    }
    h2 {
        color: #a7a7a7;
        margin: 3px;
    }
`;

export const Title = styled.h2`
    font-size: 20px;
    color: rgb(23, 116, 69);
    margin: 3px;
    padding-left: 20px;
    text-align: left;
    color: rgb(23, 116, 69);
`;
