import React from "react";
import { MainLayout } from "@nubeteck/components";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "@hooks";
import { logOut } from "src/core/store/slices/authSlice";
import { getRolesByToken, getUserNameByToken } from "src/utils/jwt-util";
import { hasRole } from "src/utils/user-util";
import { logo } from "@assets";

interface ICustomMainLayoutProps {
    children: React.ReactNode;
}

type MenuItem = React.ComponentProps<typeof MainLayout>["navBarItems"][0];

const CustomMainLayout = ({ children }: ICustomMainLayoutProps) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const userName = getUserNameByToken(localStorage.getItem("token")!);
    const token = localStorage.getItem("token");
    const roles = getRolesByToken(token!);
    const isFacilitatorOnly =
        roles.includes("facilitador") && !roles.includes("administrador(a) del sistema");

    const generateLinks = () => {
        const crumbsArray = location.pathname
            .match(/(\/\w+-?\w+-?\w+)(\/\d+)?/g)
            ?.filter((part) => part !== "" && !(part.endsWith("Id") || part.includes("log")))
            .map((e, i) => {
                return {
                    href: `/${location.pathname
                        .match(/(\/\w+-?\w+-?\w+)(\/\d+)?/g)!
                        .filter((part) => part !== "")
                        .slice(0, i + 1)
                        .join("")
                        .substring(1)}`,
                    title: e
                        .replace(/(\w)(\w*)/g, function (g0, g1, g2) {
                            return g1.toUpperCase() + g2.toLowerCase();
                        })
                        .replace(/\/\d+/g, "")
                        .replace("-", " ")
                        .substring(1, e.length),
                };
            });
        return crumbsArray;
    };

    const items: any = [
        {
            id: "home",
            iconName: "home",
            label: "Inicio",
            onClick: () => navigate("/"),
        },
        {
            id: "docentes",
            iconName: "person",
            label: "Docentes",
            onClick: () => navigate("/docentes"),
            role: ["administrador(a) del sistema"],
        },
        {
            id: "facilitadores",
            iconName: "contact_mail",
            label: "Facilitadores",
            onClick: () => navigate("/facilitadores"),

            role: ["administrador(a) del sistema"],
        },
        {
            id: "capacitaciones",
            iconName: "assignment",
            label: "Capacitaciones",
            onClick: () => navigate("/capacitaciones"),

            role: ["administrador(a) del sistema"],
        },
        {
            id: "asignar-session",
            iconName: "add_task",
            label: "Asignar sesión",
            onClick: () => navigate("/asignar-session"),

            role: ["administrador(a) del sistema"],
        },
        {
            id: "inscribir-docente",
            iconName: "app_registration",
            label: "Inscribir docente",
            onClick: () => navigate("/inscribir-capacitacion-principal"),

            role: ["administrador(a) del sistema"],
        },
        {
            id: "seguimiento-sesiones",
            iconName: "next_week",
            label: isFacilitatorOnly ? "Mis sesiones" : "Seguimiento sesiones",
            onClick: () => navigate("/seguimiento-sesiones"),

            role: ["administrador(a) del sistema"],
        },
        {
            id: "seguimiento-sesiones",
            iconName: "next_week",
            label: isFacilitatorOnly ? "Mis sesiones" : "Seguimiento sesiones",
            onClick: () => navigate("/seguimiento-sesiones"),

            role: ["facilitador"],
        },
        {
            id: "capacitaciones-disponibles",
            iconName: "assignment",
            label: "Capacitaciones disponibles",
            onClick: () => navigate("/capacitaciones-disponibles"),
            role: ["director de escuela"],
        },
        {
            id: "profesores-capacitaciones",
            iconName: "trending_up",
            label: "Capacitaciones de profesores",
            onClick: () => navigate("/profesores-capacitaciones"),
            role: ["director de escuela"],
        },
        {
            id: "profesores-capacitaciones",
            iconName: "trending_up",
            label: "Sesiones de profesores",
            onClick: () => navigate("/profesores-capacitaciones"),
            role: ["coordinador"],
        },
        {
            id: "reporteria-docentes",
            iconName: "list_alt",
            label: "Reportes de Docentes",
            onClick: () => navigate("/reporteria-docentes"),
            role: ["director de escuela"],
        },
        {
            id: "reserva-cupos",
            iconName: "playlist_add",
            label: "Reserva de cupo",
            role: ["docente"],
            onClick: () => navigate("/reserva-cupos"),
        },
        {
            id: "remove-cupos",
            iconName: "remove_circle",
            label: "Retirar sesiones",
            role: ["docente"],
            onClick: () => navigate("/retirar-sesiones"),
        },
        {
            id: "certificados-obtenidos",
            iconName: "auto_stories",
            label: "Certificados",
            role: ["docente"],
            onClick: () => navigate("/certificados-obtenidos"),
        },
        {
            id: "mantenimientos",
            iconName: "settings",
            label: "Mantenimientos",
            role: ["administrador(a) del sistema"],
            children: [
                {
                    id: "tipos-capacitaciones",
                    iconName: "insert_chart_outlined",
                    label: "Tipos de capacitaciones",
                    onClick: () => navigate("/tipos-capacitaciones"),
                },
                {
                    id: "tipos-certificados",
                    iconName: "insert_chart_outlined",
                    label: "Tipos de certificados",
                    onClick: () => navigate("/tipos-certificados"),
                },
                {
                    id: "metodos-evaluacion",
                    iconName: "assignment_ind",
                    label: "Métodos de evaluación",
                    onClick: () => navigate("/metodos-evaluacion"),
                },
                {
                    id: "nacionalidades",
                    iconName: "location_on",
                    label: "Nacionalidades",
                    onClick: () => navigate("/nacionalidades"),
                },
                {
                    id: "rubros",
                    iconName: "assignment_ind",
                    label: "Rubros",
                    onClick: () => navigate("/rubros"),
                },
                {
                    id: "calificaciones",
                    iconName: "",
                    label: "Calificaciones",
                    onClick: () => navigate("/calificaciones"),
                },
                {
                    id: "periodos-seleccion",
                    iconName: "",
                    label: "Períodos de selección",
                    onClick: () => navigate("/periodos-seleccion"),
                },
            ],
        },
        {
            id: "control-usuarios",
            iconName: "supervised_user_circle",
            label: "Control de usuarios",
            role: ["administrador(a) del sistema"],
            onClick: () => navigate("/control-usuarios"),
        },
    ].reduce((acc, item) => {
        if (item.role ? hasRole(roles, item.role) : true) {
            const verify = new RegExp(`(^.*/?{item.id}$)|(^.*/?{item.id}[^-])`, "gi");
            return [
                ...acc,
                {
                    ...item,
                    overrideIsActive: true,
                    isActive:
                        item.id == "home"
                            ? /^\/$/.test(location.pathname)
                            : verify.test(location.pathname),
                    children: item.children?.map((f) => {
                        return {
                            ...f,
                            overrideIsActive: true,
                            isActive: verify.test(location.pathname),
                        };
                    }),
                },
            ];
        }
        return acc;
    }, [] as MenuItem[]);

    return (
        <MainLayout
            logoSrc={logo}
            header={{
                title: "Sistema de gestión de desarrollo docente",
                // onClickAddIcon: undefined,
                // onClickHelpIcon: undefined,
                onClickSettingsIcon: () => navigate("/usersconfig"),
            }}
            navBarItems={[...(items as MenuItem[])]}
            user={{
                avatar: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
                name: userName,
            }}
            dropdownItems={[
                { iconName: "person_outline", name: "Perfil", onClick: () => navigate("/profile") },
                {
                    iconName: "settings",
                    name: "Configuraciones",
                    onClick: () => navigate("/usersconfig"),
                },
                {
                    iconName: "power_settings_new",
                    name: "Cerrar sesión",
                    onClick: () => {
                        dispatch(logOut());
                        navigate("/login");
                    },
                },
            ]}
            breadcrumbItems={generateLinks()}
        >
            {children}
        </MainLayout>
    );
};

export default CustomMainLayout;
