import { Card } from "antd";
import styled from "styled-components";

export const CardStyled = styled(Card)`
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 8px 0px;
    position: relative;
    border: none;
    height: fit-content;
    text-align: left;
    margin-bottom: 50px;
    // padding: 30px 35px;
    transition: all 0.5s ease;

    &.flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 0 0 50px;
    width: auto;
    gap: 40px;
`;
export const Column = styled.div`
    width: ${(props: { width: string }) => props.width || "auto"};
`;

export const ImageContainer = styled.div`
    width: 35%;
    padding: 10px;
`;

export const NoDataContainer = styled.div`
    height: 135px;
    display: flex;
    justify-content: right;
    gap: 20px;
`;

//Contenedor para el NO-DATA de la seleccion
export const NoDataContainer2 = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: left;
`;
//Imgen del no data:
export const ImgNoData = styled.img`
    width: 32%;
    min-width: 143.33px;
`;

//Texto del no data:
export const StyledH3 = styled.h3`
    width: 50%;
    text-align: left;
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const ChartOptions = styled.select`
    background-color: #ebf2f3;
    height: 35px;
    border: none;
    padding: 5px;
`;
export const BarChartContainer = styled.div`
    width: 100%;
    max-height: auto;
    padding: 10px 0px;

    canvas {
        transform: scale(0.8);
    }
`;
export const PieChartContainer = styled.div`
    canvas {
        transform: scale(0.8);
    }
`;

export const SymmetricContainer = styled.div`
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
`;

export const ContainerBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Title = styled.h2`
    color: rgb(23, 116, 69);
    margin: 3px;
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    /* flex-wrap: wrap; */
    gap: 10px;
`;
export const TableRowStyled = styled.tr`
    text-align: left;
    &:nth-child(odd) > td {
        background-color: #f9f9fa;
    }
`;
export const TableStyled = styled.table`
    font-size: 13px;
    width: 100%;
    max-width: 100%;
    padding: 25px 12px;
    tr${TableRowStyled} th {
        padding: 15px 10px 15px 20px;
    }
`;

export const TableItemStyled = styled.td`
    padding: 15px 10px 15px 15px;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
`;

// export const BarChartCardStyled = styled(BarChartCard)`
//     max-height: 400px !important;
// `;
