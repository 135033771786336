import { createSlice } from "@reduxjs/toolkit";
interface Facilitators {
    facilitators: IFacilitatorState;
}
/*
interface IFormPaso2Data {
    CorreoPersonal: string;
    CorreoInstitucional: string;
    TelefonoResidencial: string;
    TelefonoMovil: string;
}
*/
interface IFacilitatorState {
    facilitators: [];
    //facilitatorFormPaso2Data: IFormPaso2Data;
}
export const facilitatorsSlice = createSlice({
    name: "facilitators",
    initialState: {
        facilitators: [],
        facilitator: {},
        //facilitatorFormPaso2Data: {},
    },
    reducers: {
        setFacilitators: (state, action) => {
            state.facilitators = action.payload;
        },
        setFacilitator: (state, action) => {
            state.facilitator = action.payload;
        },
        /*
        setFacilitatorFormPaso2Data: (state, action) => {
            state.facilitatorFormPaso2Data = action.payload;
        },
        */
    },
});

export const { setFacilitators, setFacilitator } = facilitatorsSlice.actions;

export const selectFacilitators = (state: Facilitators) => state.facilitators.facilitators;

export default facilitatorsSlice.reducer;
