import styled from "styled-components";

export const OptionsContainer = styled.div`
    left: 776px;
    top: 411px;
    position: fixed;
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    cursor: none;
    background-color: white;
    border: 1px solid #ececec;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    ${(props: { isVisible?: boolean; ref: any }) =>
        props.isVisible
            ? `     visibility: visible;
            opacity: 1;
            cursor: auto;`
            : ""}
`;
export const OptionsButton = styled.button`
    border-radius: 6px;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    gap: 7px;
    width: fit-content;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #a7a7a7;
    padding: 15px 30px 15px 10px;
    div${OptionsContainer} {
    }
`;
