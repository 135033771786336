export const themeColors = {
    // @nubeteck/components colors

    primary100: "rgb(160, 210, 168)",
    primary200: "rgb(218 235 208);",
    primary300: "rgba(68, 155, 20, 0.3)",
    primary400: "rgba(68, 155, 20, 0.4)",
    primary500: "#409544",
    primary600: "rgba(68, 155, 20, 0.6)",
    primary700: "rgb(23, 116, 69);",
    primary800: "rgba(68, 155, 20, 0.8)",
    primary900: "rgb(0 96 22);",
    colorBgContainer: "#3B3B3B",
    colorTextHeading: "#ffffff",
    colorTextDescription: "#d1cece",
    colorText: "#ffffff",
    colorBgBase: "#181818",
    colorMenuIcons: "#a7a7a7",

    // Custom colors
    loginHeading: "#808080",
    inputBorder: "#e3e3e3",
    usersCardDesc: "#26395880",
    notificationsHeading: "#263959",
    notificationsText: "#525252",
    integrationsHeading: "#5f5f5f",
    integrationsSubHeading: "#7a7a7a",
};

export type ThemeColors = keyof typeof themeColors;
