import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const facultiesApi = createApi({
    reducerPath: "facultiesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["Faculties"],
    endpoints: (builder) => ({
        getFaculties: builder.query({
            query: () => `Facultades`,
            providesTags: ["Faculties"],
        }),
    }),
});

export const { useGetFacultiesQuery } = facultiesApi;
