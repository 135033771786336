import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const certificatesApi = createApi({
    reducerPath: "certificatesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["Certificates"],
    endpoints: (builder) => ({
        getCertificates: builder.query({
            query: () => `Facultades`,
            providesTags: ["Certificates"],
        }),
        getCertificatesBySesionId: builder.mutation({
            query: (obj) => ({
                url: "Certificados/GetByEmpresa",
                method: "POST",
                body: {
                    CertificadoNombre: obj.CertificadoNombre,
                    Page: obj.Page,
                    RowsCount: obj.RowsCount,
                    SesionId: obj.SesionId,
                },
            }),
            invalidatesTags: ["Certificates"],
        }),
        getCertificatesByDocente: builder.mutation({
            query: (obj) => ({
                url: "Certificados/GetByDocente",
                method: "POST",
                body: {
                    CertificadoNombre: obj.CertificadoNombre,
                    Page: obj.Page,
                    RowsCount: obj.RowsCount,
                    DocenteId: obj.DocenteId,
                },
            }),
            invalidatesTags: ["Certificates"],
        }),
        verifyCertificateBySesionId: builder.mutation({
            query: (sesionId) => ({
                url: "Certificados/VerifyExistsBySesionId",
                method: "POST",
                body: {
                    sesionId: sesionId,
                },
            }),
            invalidatesTags: ["Certificates"],
        }),
    }),
});

export const {
    useGetCertificatesQuery,
    useGetCertificatesBySesionIdMutation,
    useVerifyCertificateBySesionIdMutation,
    useGetCertificatesByDocenteMutation,
} = certificatesApi;
