import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const formsCalculationApi = createApi({
    reducerPath: "formsCalculationApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["FormsCalculation"],
    endpoints: (builder) => ({
        getFormsCalculation: builder.query({
            query: () => `EvaluacionesMetodosFormasCalculo`,
            providesTags: ["FormsCalculation"],
        }),
    }),
});

export const { useGetFormsCalculationQuery } = formsCalculationApi;
