import React from "react";
import { Dropdown, Icon } from "@nubeteck/components";
import { OptionsButton } from "./options.styled";
import { MenuProps } from "antd";

interface IOptionsProps {
    edit?: () => void;
    remove?: () => void;
    changeState?: () => void;
    text?: string;
    open?: () => void;
    register?: () => void;
    withdraw?: () => void;
    studentsFinished?: () => void;
    actions?: Array<{
        title: string;
        function: () => void;
        icon: string;
    }>;
}

function Options({
    edit,
    open,
    changeState,
    text,
    actions,
    register,
    remove,
    withdraw,
    studentsFinished,
}: IOptionsProps) {
    const items: MenuProps["items"] = actions
        ? actions.map((item, i) => {
              return {
                  key: i,
                  onClick: item.function,
                  label: (
                      <>
                          <Icon name={item.icon} />
                          {item.title}
                      </>
                  ),
              };
          })
        : ([
              ...[
                  open && {
                      key: "1",
                      label: (
                          <OptionsButton>
                              <Icon name={""} />
                              Abrir
                          </OptionsButton>
                      ),
                      onClick: open,
                      icon: "",
                  },
              ],
              ...[
                  changeState && {
                      key: "2",
                      label: (
                          <OptionsButton>
                              <Icon name={""} />
                              {text}
                          </OptionsButton>
                      ),
                      onClick: changeState,
                  },
              ],
              ...[
                  edit && {
                      key: "3",
                      label: (
                          <OptionsButton>
                              <Icon name={""} />
                              Editar
                          </OptionsButton>
                      ),
                      onClick: edit,
                  },
              ],
              ...[
                  register && {
                      key: "4",
                      label: (
                          <OptionsButton>
                              <Icon name={""} />
                              Registrarse
                          </OptionsButton>
                      ),
                      onClick: register,
                  },
              ],
              ...[
                  remove && {
                      key: "5",
                      label: (
                          <OptionsButton>
                              <Icon name={""} />
                              Eliminar
                          </OptionsButton>
                      ),
                      onClick: remove,
                  },
              ],
              ...[
                  withdraw && {
                      key: "6",
                      label: (
                          <OptionsButton>
                              <Icon name={""} />
                              Retirarse
                          </OptionsButton>
                      ),
                      onClick: withdraw,
                  },
              ],
              ...[
                  studentsFinished && {
                      key: "7",
                      label: (
                          <OptionsButton>
                              <Icon name={""} />
                              Certificados
                          </OptionsButton>
                      ),
                      onClick: studentsFinished,
                  },
              ],
          ].filter((item) => item != undefined) as MenuProps["items"]);

    return (
        <Dropdown menu={{ items }} trigger={["click"]}>
            <OptionsButton onClick={(e) => e.preventDefault()}>
                <Icon name={""} />
            </OptionsButton>
        </Dropdown>
    );
}

export default Options;
