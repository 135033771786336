import { createSlice } from "@reduxjs/toolkit";
interface Certificates {
    certificates: ICertificateState;
}
interface ICertificateState {
    certificates: [];
}

export const certificatesSlice = createSlice({
    name: "certificates",
    initialState: {
        certificates: [],
    },
    reducers: {
        setCertificates: (state, action) => {
            state.certificates = action.payload;
        },
    },
});

export const { setCertificates } = certificatesSlice.actions;

export const selectCertificates = (state: Certificates) => state.certificates.certificates;

export default certificatesSlice.reducer;
