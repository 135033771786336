import { Card } from "antd";
import styled from "styled-components";

export const MosaicCard = styled(Card)`
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 1px solid var(--color-bg);
    width: 28%;
    position: relative;
    margin-bottom: 30px;
`;

export const MosaicImageContainer = styled.div`
    height: 140px;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

export const NoImageContainer = styled.div`
    background: #e4e6e7;
    height: 140px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MosaicInfoContainer = styled.div`
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    .options {
        position: absolute;
        right: 5px;
    }
`;

export const MosaicTitle = styled.span`
    color: rgb(23, 116, 69);
    font-size: 15px;
    font-weight: bold;
`;
export const MosaicSubtitle = styled.span`
    font-size: 13px;
    font-weight: bold;
    color: #294f22;
`;
