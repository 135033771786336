import { createSlice } from "@reduxjs/toolkit";
interface Schools {
    schools: ISchoolState;
}
interface ISchoolState {
    schools: [];
}

export const schoolsSlice = createSlice({
    name: "schools",
    initialState: {
        schools: [],
    },
    reducers: {
        setSchools: (state, action) => {
            state.schools = action.payload;
        },
    },
});

export const { setSchools } = schoolsSlice.actions;

export const selectSchools = (state: Schools) => state.schools.schools;

export default schoolsSlice.reducer;
