import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const appIdsApi = createApi({
    reducerPath: "appIdsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["AppIds"],
    endpoints: (builder) => ({
        getAppIds: builder.query({
            query: () => `AppId`,
            providesTags: ["AppIds"],
        }),
        getAppIdById: builder.query({
            query: (id) => `AppId/${id}`,
            providesTags: ["AppIds"],
        }),
        getTiposAppIds: builder.query({
            query: () => `AppId`,
            providesTags: ["AppIds"],
        }),
        createAppId: builder.mutation({
            query: (newAppId) => ({
                url: "/AppId",
                method: "POST",
                body: newAppId,
            }),
            invalidatesTags: ["AppIds"],
        }),
        updateAppId: builder.mutation({
            query: (updateAppId) => ({
                url: `/AppId/${updateAppId.AppId}`,
                method: "PUT",
                body: updateAppId,
            }),
            invalidatesTags: ["AppIds"],
        }),
    }),
});

export const {
    useGetAppIdByIdQuery,
    useGetAppIdsQuery,
    useGetTiposAppIdsQuery,
    useCreateAppIdMutation,
    useUpdateAppIdMutation,
} = appIdsApi;
