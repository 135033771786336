import { createSlice } from "@reduxjs/toolkit";
interface Teachers {
    teachers: ITeacherState;
}
interface ITeacherState {
    teachers: [];
    teacher: object;
}

export const teachersSlice = createSlice({
    name: "teachers",
    initialState: {
        teachers: [],
        teacher: {},
    },
    reducers: {
        setTeachers: (state, action) => {
            state.teachers = action.payload;
        },
        setTeacher: (state, action) => {
            state.teacher = action.payload;
        },
    },
});

export const { setTeachers } = teachersSlice.actions;

export const selectTeachers = (state: Teachers) => state.teachers.teachers;

export default teachersSlice.reducer;
