import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { JwtUtils } from "@utils";

const RequireLogin = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        const token = localStorage.getItem("token");

        if (!JwtUtils.verifyTokenExpiration(token)) {
            navigate("/login");
        }
    }, [navigate]);

    return <Outlet />;
};

export default RequireLogin;
