import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const logsApi = createApi({
    reducerPath: "logsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["logs"],
    endpoints: (builder) => ({
        getlogs: builder.mutation({
            query: (param: string) =>
                !param.includes("Id")
                    ? {
                          url: "/logs/GetLogsByTableName",
                          method: "POST",
                          body: { tableName: param },
                      }
                    : { url: "/logs/GetLogsByRegistroId", method: "POST", body: { id: param } },
            invalidatesTags: ["logs"],
        }),
    }),
});

export const { useGetlogsMutation } = logsApi;
