import React, { useEffect } from "react";
import {
    PageActionsButton,
    PageActionsContainer,
    PageActionsDropdown,
    PageActionsPagination,
} from "./page-actions.styled";

interface IPageActionsProps {
    count: number;
    onChange: (page: number) => void;
    registersCount: number;
    changeRegistersCount: (number: string) => void;
}

const PageActions = ({
    count,
    onChange,
    registersCount,
    changeRegistersCount,
}: IPageActionsProps) => {
    const [currentPage, setCurrentPage] = React.useState(1);
    console.log(currentPage, "currentPage");

    useEffect(() => {
        onChange(currentPage);
    }, [currentPage, onChange]);

    const renderPagesBtn = () => {
        const buttonArray = [];
        for (let i = 1; i <= count; i++) {
            buttonArray?.push(
                <PageActionsButton
                    key={i}
                    className={currentPage == i ? "is-selected" : ""}
                    onClick={() => setCurrentPage(i)}
                >
                    {i}
                </PageActionsButton>,
            );
        }
        return buttonArray;
    };

    return (
        <PageActionsContainer>
            <span>
                Mostrando
                <PageActionsDropdown
                    onChange={(e) => changeRegistersCount(e.target.value)}
                    value={registersCount}
                >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                </PageActionsDropdown>
                registros por página
            </span>
            <PageActionsPagination>
                <PageActionsButton
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage == 1}
                >
                    Anteriores
                </PageActionsButton>
                {renderPagesBtn()}
                <PageActionsButton
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage == count}
                >
                    Siguientes
                </PageActionsButton>
            </PageActionsPagination>
        </PageActionsContainer>
    );
};
export default PageActions;
