import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const capacitationModulesCapacitationsApi = createApi({
    reducerPath: "capacitationModulesCapacitationsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["CapacitationModulesCapacitations"],
    endpoints: (builder) => ({
        getModulesCapacitations: builder.mutation({
            query: (id) => ({
                url: "/CapacitacionesModulosCapacitaciones/GetByModuloId",
                method: "POST",
                body: { moduloId: id },
            }),
            invalidatesTags: ["CapacitationModulesCapacitations"],
        }),
    }),
});

export const { useGetModulesCapacitationsMutation } = capacitationModulesCapacitationsApi;
