import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const studentsScoresApi = createApi({
    reducerPath: "studentsScoresApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["StudentsScores"],
    endpoints: (builder) => ({
        getStudentsScores: builder.query({
            query: () => `CapacitacionesEstudiantesCalificaciones`,
            providesTags: ["StudentsScores"],
        }),
        getStudentsScoresByStudentId: builder.mutation({
            query: (studentId) => ({
                url: "/CapacitacionesEstudiantesCalificaciones/GetByEstudianteId",
                method: "POST",
                body: { CapacitacionEstudianteId: studentId },
            }),
            invalidatesTags: ["StudentsScores"],
        }),
        getStudentsScoresGetBySesionId: builder.mutation({
            query: (sesionId) => ({
                url: "/CapacitacionesEstudiantesCalificaciones/GetBySesionId",
                method: "POST",
                body: { sesionId: sesionId },
            }),
            invalidatesTags: ["StudentsScores"],
        }),
        createStudentsScore: builder.mutation({
            query: (newStudentsScore) => ({
                url: "/CapacitacionesEstudiantesCalificaciones",
                method: "POST",
                body: newStudentsScore,
            }),
            invalidatesTags: ["StudentsScores"],
        }),
        updateStudentsScore: builder.mutation({
            query: (updateStudentsScore) => ({
                url: `/CapacitacionesEstudiantesCalificaciones/${updateStudentsScore.Id}`,
                method: "PUT",

                body: updateStudentsScore,
            }),
            invalidatesTags: ["StudentsScores"],
        }),
        deleteStudentsScore: builder.mutation({
            query: (studentsScoreId: number) => ({
                url: `/CapacitacionesEstudiantesCalificaciones/${studentsScoreId}`,
                method: "DELETE",
                body: studentsScoreId,
            }),
            invalidatesTags: ["StudentsScores"],
        }),
    }),
});

export const {
    useGetStudentsScoresQuery,
    useGetStudentsScoresByStudentIdMutation,
    useGetStudentsScoresGetBySesionIdMutation,
    useCreateStudentsScoreMutation,
    useUpdateStudentsScoreMutation,
    useDeleteStudentsScoreMutation,
} = studentsScoresApi;
