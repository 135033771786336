import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const capacitationsApi = createApi({
    reducerPath: "capacitationsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["Capacitations"],
    endpoints: (builder) => ({
        getCapacitations: builder.query({
            query: () => `Capacitaciones`,
            providesTags: ["Capacitations"],
        }),
        getCapacitationById: builder.query({
            query: (id) => `Capacitaciones/${id}`,
            providesTags: ["Capacitations"],
        }),
        getCapacitationsV2: builder.mutation({
            query: (q: string) => ({
                url: "/Capacitaciones/GetCapacitaciones",
                method: "POST",
                body: { q },
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getCapacitationsV3: builder.mutation({
            // <--- StoreProcedure With FkNames
            query: (q: string) => ({
                url: "/Capacitaciones/GetCapacitacionesWithFKName",
                method: "POST",
                body: { q },
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getCapacitationsBySchool: builder.mutation({
            query: (q: string) => ({
                url: "/Capacitaciones/GetCapacitacionesActivasByEscuela",
                method: "POST",
                body: { q: q },
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getCapacitacionesPrincipalesByDocente: builder.mutation({
            query: (docenteId: number) => ({
                url: "/Capacitaciones/GetCapacitacionesPrincipalesByDocente",
                method: "POST",
                body: { docenteId: docenteId },
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getCapacitacionesPrincipalesInscritasOptions: builder.mutation({
            query: () => ({
                url: "/Capacitaciones/GetCapacitacionesPrincipalesInscritasOptions",
                method: "POST",
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getCapacitacionesPrincipalesInscritasOptionsByDocenteId: builder.mutation({
            query: (docenteId: number) => ({
                url: "/Capacitaciones/GetCapacitacionesPrincipalesInscritasOptionsByDocenteId",
                method: "POST",
                body: { docenteId: docenteId },
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getPrincipalCapacitations: builder.mutation({
            query: () => ({
                url: "/Capacitaciones/GetCapacitacionesPrincipales",
                method: "POST",
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getDetailCapacitation: builder.mutation({
            query: (id: number) => ({
                url: "/Capacitaciones/GetDetalle",
                method: "POST",
                body: { CapacitacionId: id },
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getCalculateWay: builder.mutation({
            query: (id: number) => ({
                url: "/Capacitaciones/GetFormaCalculoByCapacitacionId",
                method: "POST",
                body: { CapacitacionId: id },
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getCapacitationsByPrincipalCapacitation: builder.mutation({
            query: (id: number) => ({
                url: "/Capacitaciones/GetCapacitacionesByCapacitacionPrincipalId",
                method: "POST",
                body: { capacitacionId: id },
            }),
            invalidatesTags: ["Capacitations"],
        }),
        GetPrincipalCapacitationsWithFKName: builder.mutation({
            query: (q: string) => ({
                url: "/Capacitaciones/GetPrincipalCapacitationsWithFKName",
                method: "POST",
                body: { q },
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getPrincipalCapacitationsBySchool: builder.mutation({
            query: () => ({
                url: "/Capacitaciones/GetCapacitacionesPrincipalesByEscuela",
                method: "POST",
            }),
            invalidatesTags: ["Capacitations"],
        }),
        createCapacitation: builder.mutation({
            query: (newCapacitation) => ({
                url: "/Capacitaciones",
                method: "POST",
                body: newCapacitation,
            }),
            invalidatesTags: ["Capacitations"],
        }),
        updateCapacitation: builder.mutation({
            query: (updateCapacitation) => ({
                url: `/Capacitaciones/${updateCapacitation.CapacitacionId}`,
                method: "PUT",
                body: updateCapacitation,
            }),
            invalidatesTags: ["Capacitations"],
        }),
        deleteCapacitation: builder.mutation({
            query: (capacitationId: number) => ({
                url: `/Capacitaciones/${capacitationId}`,
                method: "DELETE",
                body: capacitationId,
            }),
            invalidatesTags: ["Capacitations"],
        }),
        verificarCodigoCapacitacion: builder.mutation({
            query: (codigo: string) => ({
                url: `/Capacitaciones/VerificarCodigoCapacitacion`,
                method: "POST",
                body: { Codigo: codigo },
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getDataForSelectOption: builder.mutation({
            query: (parameters: { q: string; escuelasIds: string }) => ({
                url: `/Capacitaciones/GetDataForSelectOption`,
                method: "POST",
                body: parameters,
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getDataBasicByCapacitationId: builder.mutation({
            query: (Id: number) => ({
                url: `/Capacitaciones/GetDataBasicByCapacitationId`,
                method: "POST",
                body: { capacitacionId: Id },
            }),
            invalidatesTags: ["Capacitations"],
        }),
    }),
});

export const {
    useGetCapacitationsQuery,
    useGetCapacitationByIdQuery,
    useCreateCapacitationMutation,
    useGetCalculateWayMutation,
    useGetPrincipalCapacitationsBySchoolMutation,
    useGetCapacitationsV2Mutation,
    useGetCapacitationsV3Mutation,
    useGetCapacitationsBySchoolMutation,
    useGetCapacitacionesPrincipalesByDocenteMutation,
    useGetCapacitacionesPrincipalesInscritasOptionsMutation,
    useGetCapacitacionesPrincipalesInscritasOptionsByDocenteIdMutation,
    useGetPrincipalCapacitationsMutation,
    useGetDetailCapacitationMutation,
    useGetCapacitationsByPrincipalCapacitationMutation,
    useGetPrincipalCapacitationsWithFKNameMutation,
    useUpdateCapacitationMutation,
    useDeleteCapacitationMutation,
    useVerificarCodigoCapacitacionMutation,
    useGetDataForSelectOptionMutation,
    useGetDataBasicByCapacitationIdMutation,
} = capacitationsApi;
