import { createSlice } from "@reduxjs/toolkit";
interface Rubros {
    rubros: IRubroState;
}
interface IRubroState {
    rubros: [];
}

export const rubrosSlice = createSlice({
    name: "rubros",
    initialState: {
        rubros: [],
    },
    reducers: {
        setRubros: (state, action) => {
            state.rubros = action.payload;
        },
    },
});

export const { setRubros } = rubrosSlice.actions;

export const selectRubros = (state: Rubros) => state.rubros.rubros;

export default rubrosSlice.reducer;
