import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const periodsApi = createApi({
    reducerPath: "periodsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["Periodos"],
    endpoints: (builder) => ({
        getPeriods: builder.query({
            query: () => `Periodos`,
            providesTags: ["Periodos"],
        }),
    }),
});

export const { useGetPeriodsQuery } = periodsApi;
