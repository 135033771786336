import React from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import Error from "../../assets/NoResults.svg";
import { ErrorPage } from "./error-boundary.styled";
const ErrorBoundary = () => {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return (
                <ErrorPage>
                    <img src={Error} alt="Página no encontrada"></img>
                    <h1 className="grey-text">Página no encontrada</h1>
                </ErrorPage>
            );
        }
        return (
            <div>
                <h1>Oops!</h1>
                <h2>{}</h2>
                <p>{error.statusText}</p>
                {error.data?.message && <p>{error.data.message}</p>}
            </div>
        );
    } else {
        return <div>Oops</div>;
    }
};

export default ErrorBoundary;
