import { createSlice } from "@reduxjs/toolkit";
interface SelectionsPeriods {
    selectionsPeriods: ISelectionPeriodState;
}
interface ISelectionPeriodState {
    selectionsPeriods: [];
}

export const sesionCapacitationsSlice = createSlice({
    name: "selectionPeriods",
    initialState: {
        selectionsPeriods: [],
        selectionPeriod: {},
    },
    reducers: {
        setSelectionsPeriods: (state, action) => {
            state.selectionsPeriods = action.payload;
        },
        setSelectionPeriod: (state, action) => {
            state.selectionPeriod = action.payload;
        },
    },
});

export const { setSelectionsPeriods, setSelectionPeriod } = sesionCapacitationsSlice.actions;

export const selectSesionCapacitations = (state: SelectionsPeriods) =>
    state.selectionsPeriods.selectionsPeriods;

export default sesionCapacitationsSlice.reducer;
