import "reflect-metadata";

import React from "react";
import { Layout, ThemeProvider } from "@nubeteck/components";
import { Provider as ReduxProvider } from "react-redux";

import { store } from "./core";
import { Loading } from "./components";
import { themeColors } from "./core";

const App = React.lazy(() => import("./App"));

const InitializeApp = () => {
    const renderLoading = React.useCallback(() => {
        return (
            <Layout style={{ width: "100vw", height: "100vh" }}>
                <Loading />
            </Layout>
        );
    }, []);

    return (
        <ThemeProvider
            theme={{
                mode: "light",
                colors: themeColors,
                fontFamily: "Mulish",
                config: { themedScrollbar: true },
                locale: {
                    emptyText: "",
                    errorText: "Error",
                },
            }}
        >
            <ReduxProvider store={store}>
                <React.Suspense fallback={renderLoading()}>
                    <App />
                </React.Suspense>
            </ReduxProvider>
        </ThemeProvider>
    );
};

export default InitializeApp;
