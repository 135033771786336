import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const capacitacionesPrincipalesOficializacionesSeleccionesApi = createApi({
    //
    reducerPath: "capacitacionesPrincipalesOficializacionesSeleccionesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["PrincipalCapacitationsOfficializationsSelections"],
    endpoints: (builder) => ({
        verificarOficializacionSelCapPrincipal: builder.mutation({
            query: (parameters: {
                estudianteId: number;
                periodoId: number;
                anio: number;
                capacitacionPrincipalId: number;
            }) => ({
                url: "/CapacitacionesPrincipalesOficializacionesSelecciones/VerificarOfficializacion",
                method: "POST",
                body: parameters,
            }),
            invalidatesTags: ["PrincipalCapacitationsOfficializationsSelections"],
        }),
    }),
});

export const { useVerificarOficializacionSelCapPrincipalMutation } =
    capacitacionesPrincipalesOficializacionesSeleccionesApi;
