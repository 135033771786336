import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const academicLevelsApi = createApi({
    reducerPath: "academicLevelsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["AcademicLevels"],
    endpoints: (builder) => ({
        getAcademicLevels: builder.query({
            query: () => `NivelesAcademicos`,
            providesTags: ["AcademicLevels"],
        }),
        createAcademicLevel: builder.mutation({
            query: (newAcademicLevel) => ({
                url: "/NivelesAcademicos",
                method: "POST",
                body: newAcademicLevel,
            }),
            invalidatesTags: ["AcademicLevels"],
        }),
        updateAcademicLevel: builder.mutation({
            query: (updateAcademicLevel) => ({
                url: `/NivelesAcademicos/${updateAcademicLevel.NivelAcademicoId}`,
                method: "PUT",

                body: updateAcademicLevel,
            }),
            invalidatesTags: ["AcademicLevels"],
        }),
        deleteAcademicLevels: builder.mutation({
            query: (nivelAcademicoId: number) => ({
                url: `/NivelesAcademicos/${nivelAcademicoId}`,
                method: "DELETE",
                body: nivelAcademicoId,
            }),
            invalidatesTags: ["AcademicLevels"],
        }),
    }),
});

export const {
    useGetAcademicLevelsQuery,
    useCreateAcademicLevelMutation,
    useUpdateAcademicLevelMutation,
    useDeleteAcademicLevelsMutation,
} = academicLevelsApi;
