import React from "react";
import NoImg from "../../assets/no-img.png";
import { Options } from "../options";
import {
    MosaicCard,
    MosaicImageContainer,
    MosaicInfoContainer,
    MosaicSubtitle,
    MosaicTitle,
    NoImageContainer,
} from "./mosaic.styled";
import { Icon } from "@nubeteck/components";

interface IMosaicProps {
    isLoading: boolean;
    imagen: string;
    title: string;
    noOptions?: boolean;
    subtitle?: string;
    extraInfo?: JSX.Element;
    edit?: () => void;
    remove?: () => void;
    open?: () => void;
    register?: () => void;
    changeState?: () => void | undefined;
    text?: string;
    withdraw?: () => void;
    studentsFinished?: () => void;
}
export default function Mosaic(props: IMosaicProps) {
    return (
        <MosaicCard loading={props.isLoading} hoverable>
            <MosaicImageContainer onClick={props.open}>
                {props.imagen !== "" ? (
                    <img src={props.imagen ? props.imagen : NoImg} alt="Imagen del registro"></img>
                ) : (
                    <NoImageContainer>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Icon size={70} color="#AEB4B7" name={""} />
                            <span style={{ color: "#AEB4B7", fontWeight: "bold" }}>Sin imagen</span>
                        </div>
                    </NoImageContainer>
                )}
            </MosaicImageContainer>
            <MosaicInfoContainer>
                <div
                    onClick={props.open}
                    onKeyDown={props.open}
                    role="presentation"
                    style={{ width: "100%" }}
                >
                    <MosaicTitle>{props.title}</MosaicTitle>
                    <br />
                    <MosaicSubtitle>{props.subtitle}</MosaicSubtitle>
                    {props.extraInfo && props.extraInfo}
                </div>
                {!props.noOptions && (
                    <div className="options">
                        <Options
                            edit={props.edit}
                            remove={props.remove}
                            open={props.open}
                            changeState={props.changeState}
                            text={props.text}
                            register={props.register}
                            withdraw={props.withdraw}
                            studentsFinished={props.studentsFinished}
                        />
                    </div>
                )}
            </MosaicInfoContainer>
        </MosaicCard>
    );
}
