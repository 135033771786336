import styled from "styled-components";

export const PageActionsContainer = styled.div`
font-size: 13px;
font-size: #a7a7a7
position: relative;
padding: 0 20px;
display: flex;
flex-direction: row;
align-items: center;
gap: 10px;
span {
    color:#294f22;
    font-weight: bold;
}
`;

export const PageActionsDropdown = styled.select`
    border: none;
    margin: 0 10px;
    background-color: white;
    color: #a7a7a7;
`;

export const PageActionsPagination = styled.div`
    position: absolute;
    right: 2%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

export const PageActionsButton = styled.button`
    padding: 5px 10px;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    gap: 7px;
    width: fit-content;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 6px;
    &.is-selected {
        background-color: rgb(160, 210, 168);
    }
`;
