import styled from "styled-components";

export const ErrorPage = styled.div`
    max-height: 100vh;
    min-height: 100vh;
    padding: 7%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;
