import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const evaluationMethodsApi = createApi({
    reducerPath: "evaluationMethodsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["EvaluationMethods"],
    endpoints: (builder) => ({
        getEvaluationMethods: builder.query({
            query: () => `EvaluacionesMetodos`,
            providesTags: ["EvaluationMethods"],
        }),
        getEvaluacionesMetodosGetAllWithFkName: builder.mutation({
            query: (q: string) => ({
                url: "/EvaluacionesMetodos/GetAllWithFkName",
                method: "POST",
                body: { q },
            }),
            invalidatesTags: ["EvaluationMethods"],
        }),
        createEvaluationMethod: builder.mutation({
            query: (newEvaluationMethod) => ({
                url: "/EvaluacionesMetodos",
                method: "POST",
                body: newEvaluationMethod,
            }),
            invalidatesTags: ["EvaluationMethods"],
        }),
        updateEvaluationMethod: builder.mutation({
            query: (updateEvaluationMethod) => ({
                url: `/EvaluacionesMetodos/${updateEvaluationMethod.MetodoEvaluacionId}`,
                method: "PUT",
                body: updateEvaluationMethod,
            }),
            invalidatesTags: ["EvaluationMethods"],
        }),
        deleteEvaluationMethod: builder.mutation({
            query: (evaluationMethodId: number) => ({
                url: `/EvaluacionesMetodos/${evaluationMethodId}`,
                method: "DELETE",
                body: evaluationMethodId,
            }),
            invalidatesTags: ["EvaluationMethods"],
        }),
    }),
});

export const {
    useGetEvaluationMethodsQuery,
    useGetEvaluacionesMetodosGetAllWithFkNameMutation,
    useCreateEvaluationMethodMutation,
    useUpdateEvaluationMethodMutation,
    useDeleteEvaluationMethodMutation,
} = evaluationMethodsApi;
