import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const teachersSourceApi = createApi({
    reducerPath: "teachersSourceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["TeachersSource"],
    endpoints: (builder) => ({
        getTeachersSource: builder.query({
            query: () => `ExternalDocentes`,
            providesTags: ["TeachersSource"],
        }),
    }),
});

export const { useGetTeachersSourceQuery } = teachersSourceApi;
