import { createSlice } from "@reduxjs/toolkit";
interface EvaluationMethods {
    evaluationMethods: IEvaluationMethodState;
}
interface IEvaluationMethodState {
    evaluationMethods: [];
}

export const evaluationMethodsSlice = createSlice({
    name: "evaluationMethods",
    initialState: {
        evaluationMethods: [],
    },
    reducers: {
        setEvaluationMethods: (state, action) => {
            state.evaluationMethods = action.payload;
        },
    },
});

export const { setEvaluationMethods } = evaluationMethodsSlice.actions;

export const selectEvaluationMethods = (state: EvaluationMethods) =>
    state.evaluationMethods.evaluationMethods;

export default evaluationMethodsSlice.reducer;
