import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const sessionCapacitationTypesApi = createApi({
    reducerPath: "sessionCapacitationTypesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["SessionCapacitationTypes"],
    endpoints: (builder) => ({
        getSessionCapacitationTypes: builder.query({
            query: () => `CapacitacionesSesionesTipos`,
            providesTags: ["SessionCapacitationTypes"],
        }),
    }),
});

export const { useGetSessionCapacitationTypesQuery } = sessionCapacitationTypesApi;
