import { createSlice } from "@reduxjs/toolkit";
interface Period {
    periods: IPeriodState;
}
interface IPeriodState {
    periods: [];
}

export const periodsSlice = createSlice({
    name: "periods",
    initialState: {
        periods: [],
    },
    reducers: {
        setPeriods: (state, action) => {
            state.periods = action.payload;
        },
    },
});

export const { setPeriods } = periodsSlice.actions;

export const selectPeriods = (state: Period) => state.periods.periods;

export default periodsSlice.reducer;
