import { createSlice } from "@reduxjs/toolkit";
interface SesionCapacitations {
    sesionCapacitations: ISesionCapacitationState;
}
interface ISesionCapacitationState {
    sesionCapacitations: [];
}

export const sesionCapacitationsSlice = createSlice({
    name: "sesionCapacitations",
    initialState: {
        sesionCapacitations: [],
        sesionCapacitation: {},
        periodAmountBySessionCapacitation: {},
        teacherSesion: [],
    },
    reducers: {
        setSesionCapacitations: (state, action) => {
            state.sesionCapacitations = action.payload;
        },
        setSesionCapacitation: (state, action) => {
            state.sesionCapacitation = action.payload;
        },
        setPeriodAmount: (state, action) => {
            state.periodAmountBySessionCapacitation = action.payload;
        },
        setTeacherSesion: (state, action) => {
            state.teacherSesion = action.payload;
        },
    },
});

export const { setSesionCapacitations, setSesionCapacitation, setPeriodAmount, setTeacherSesion } =
    sesionCapacitationsSlice.actions;

export const selectSesionCapacitations = (state: SesionCapacitations) =>
    state.sesionCapacitations.sesionCapacitations;

export default sesionCapacitationsSlice.reducer;
