import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const identityDocumentsTypesApi = createApi({
    reducerPath: "identityDocumentsTypesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["IdentityDocumentsTypes"],
    endpoints: (builder) => ({
        getIdentityDocumentsTypes: builder.query({
            query: () => `DocumentosIdentidadTipos`,
            providesTags: ["IdentityDocumentsTypes"],
        }),
        createIdentityDocumentsTypes: builder.mutation({
            query: (newIdentityDocumentsTypes) => ({
                url: "/DocumentosIdentidadTipos",
                method: "POST",
                body: newIdentityDocumentsTypes,
            }),
            invalidatesTags: ["IdentityDocumentsTypes"],
        }),
        updateIdentityDocumentsTypes: builder.mutation({
            query: (updateIdentityDocumentsTypes) => ({
                url: `/DocumentosIdentidadTipos/${updateIdentityDocumentsTypes.NacionalidadId}`,
                method: "PUT",

                body: updateIdentityDocumentsTypes,
            }),
            invalidatesTags: ["IdentityDocumentsTypes"],
        }),
        deleteIdentityDocumentsTypes: builder.mutation({
            query: (nacionalidadId: number) => ({
                url: `/DocumentosIdentidadTipos/${nacionalidadId}`,
                method: "DELETE",
                body: nacionalidadId,
            }),
            invalidatesTags: ["IdentityDocumentsTypes"],
        }),
    }),
});

export const {
    useGetIdentityDocumentsTypesQuery,
    useCreateIdentityDocumentsTypesMutation,
    useUpdateIdentityDocumentsTypesMutation,
    useDeleteIdentityDocumentsTypesMutation,
} = identityDocumentsTypesApi;
