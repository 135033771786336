import { Card } from "antd";
import styled from "styled-components";

export const TittleDark = styled.h2`
    font-size: 20px;
    color: rgb(23, 116, 69);
    margin: 3px;
    padding-left: 20px;
    text-align: left;
    font-weight: bold;
`;

export const MosaicContainer = styled.div`
    padding: 30px 20px;
    flex-wrap: wrap;
    gap: 6%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const MosaicTemplateContainer = styled.div`
    padding: 5px 5px;
    flex-wrap: wrap;
    gap: 6%;
    display: flex;
    flex-direction: row;
    align-items: center;]
`;

export const TableContainer = styled.div`
    min-width: 100%;
    min-height: 100%;
    position: relative;
    overflow: auto;
`;

export const FacilitatorsContainer = styled(Card)`
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
`;
