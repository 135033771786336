import { createSlice } from "@reduxjs/toolkit";
interface Capacitations {
    capacitations: ICapacitationState;
}
interface ICapacitationState {
    capacitations: [];
}

export const capacitationsSlice = createSlice({
    name: "capacitations",
    initialState: {
        capacitations: [],
        capacitation: {},
        principalCapacitations: [],
    },
    reducers: {
        setCapacitations: (state, action) => {
            state.capacitations = action.payload;
        },
        setCapacitation: (state, action) => {
            state.capacitation = action.payload;
        },
        setPrincipalCapacitations: (state, action) => {
            state.principalCapacitations = action.payload;
        },
    },
});

export const { setCapacitations, setCapacitation, setPrincipalCapacitations } =
    capacitationsSlice.actions;

export const selectCapacitations = (state: Capacitations) => state.capacitations.capacitations;

export default capacitationsSlice.reducer;
