import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const rubrosApi = createApi({
    reducerPath: "rubrosApi",
    baseQuery: fetchBaseQuery({
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["Rubros"],
    endpoints: (builder) => ({
        getRubros: builder.query({
            query: () => `EvaluacionesRubros`,
            providesTags: ["Rubros"],
        }),
        getRubrosAndSearch: builder.mutation({
            query: (q) => ({
                url: "/EvaluacionesRubros/GetRubros",
                method: "POST",
                body: { q: q },
            }),
            invalidatesTags: ["Rubros"],
        }),
        createRubro: builder.mutation({
            query: (newRubro) => ({
                url: "/EvaluacionesRubros",
                method: "POST",
                body: newRubro,
            }),
            invalidatesTags: ["Rubros"],
        }),
        updateRubro: builder.mutation({
            query: (updateRubro) => ({
                url: `/EvaluacionesRubros/${updateRubro.RubroId}`,
                method: "PUT",
                body: updateRubro,
            }),
            invalidatesTags: ["Rubros"],
        }),
        deleteRubro: builder.mutation({
            query: (rubroId: number) => ({
                url: `/EvaluacionesRubros/${rubroId}`,
                method: "DELETE",
                body: rubroId,
            }),
            invalidatesTags: ["Rubros"],
        }),
    }),
});

export const {
    useGetRubrosQuery,
    useGetRubrosAndSearchMutation,
    useCreateRubroMutation,
    useUpdateRubroMutation,
    useDeleteRubroMutation,
} = rubrosApi;
