import { createSlice } from "@reduxjs/toolkit";
interface EnrollPrincipalCapacitations {
    enrollPrincipalCapacitations: IEnrollPrincipalCapacitationState;
}
interface IEnrollPrincipalCapacitationState {
    enrollPrincipalCapacitations: [];
}

export const enrollPrincipalCapacitationsSlice = createSlice({
    name: "enrollPrincipalCapacitations",
    initialState: {
        enrollPrincipalCapacitations: [],
        enrollPrincipalCapacitation: {},
    },
    reducers: {
        setEnrollPrincipalCapacitations: (state, action) => {
            state.enrollPrincipalCapacitations = action.payload;
        },
        setEnrollPrincipalCapacitation: (state, action) => {
            state.enrollPrincipalCapacitation = action.payload;
        },
    },
});

export const { setEnrollPrincipalCapacitations, setEnrollPrincipalCapacitation } =
    enrollPrincipalCapacitationsSlice.actions;

export const selectEnrollPrincipalCapacitations = (state: EnrollPrincipalCapacitations) =>
    state.enrollPrincipalCapacitations.enrollPrincipalCapacitations;

export default enrollPrincipalCapacitationsSlice.reducer;
