import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const nationalitiesApi = createApi({
    reducerPath: "nationalitiesApi",
    baseQuery: fetchBaseQuery({
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["Nationalities"],
    endpoints: (builder) => ({
        getNationalities: builder.query({
            query: () => `Nacionalidades`,
            providesTags: ["Nationalities"],
        }),
        getNationalitiesAndSearch: builder.mutation({
            query: (q) => ({
                url: "/Nacionalidades/GetNacionalidades",
                method: "POST",
                body: { q: q },
            }),
            invalidatesTags: ["Nationalities"],
        }),
        createNationality: builder.mutation({
            query: (newNationality) => ({
                url: "/Nacionalidades",
                method: "POST",
                body: newNationality,
            }),
            invalidatesTags: ["Nationalities"],
        }),
        updateNationality: builder.mutation({
            query: (updateNationality) => ({
                url: `/Nacionalidades/${updateNationality.NacionalidadId}`,
                method: "PUT",

                body: updateNationality,
            }),
            invalidatesTags: ["Nationalities"],
        }),
        deleteNationality: builder.mutation({
            query: (nationalityId: number) => ({
                url: `/Nacionalidades/${nationalityId}`,
                method: "DELETE",
                body: nationalityId,
            }),
            invalidatesTags: ["Nationalities"],
        }),
    }),
});

export const {
    useGetNationalitiesQuery,
    useGetNationalitiesAndSearchMutation,
    useCreateNationalityMutation,
    useUpdateNationalityMutation,
    useDeleteNationalityMutation,
} = nationalitiesApi;
