import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const evaluationRubrosApi = createApi({
    reducerPath: "evaluationRubrosApi",
    baseQuery: fetchBaseQuery({
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["EvaluationRubros"],
    endpoints: (builder) => ({
        getEvaluationRubros: builder.query({
            query: () => `EvaluacionesMetodosRubros`,
            providesTags: ["EvaluationRubros"],
        }),
        getEvaluationRubrosByCapacitacionId: builder.mutation({
            query: (capacitacionId) => ({
                url: "/EvaluacionesMetodosRubros/GetByCapacitacionId",
                method: "POST",
                body: { CapacitacionId: capacitacionId },
            }),
            invalidatesTags: ["EvaluationRubros"],
        }),
        getEvaluationRubrosByMetodoEvaluacionId: builder.mutation({
            query: (metodoEvaluacionId) => ({
                url: "/EvaluacionesMetodosRubros/ByMetodoEvaluacionId",
                method: "POST",
                body: { metodoEvaluacionId: metodoEvaluacionId },
            }),
            invalidatesTags: ["EvaluationRubros"],
        }),
        getEvaluacionesMetodosRubrosGetAll: builder.mutation({
            query: (q: string) => ({
                url: "/EvaluacionesMetodosRubros/GetAllWithFkName",
                method: "POST",
                body: { q },
            }),
            invalidatesTags: ["EvaluationRubros"],
        }),
        createEvaluationRubro: builder.mutation({
            query: (newEvaluationRubro) => ({
                url: "/EvaluacionesMetodosRubros",
                method: "POST",
                body: newEvaluationRubro,
            }),
            invalidatesTags: ["EvaluationRubros"],
        }),
        updateEvaluationRubro: builder.mutation({
            query: (updateEvaluationRubro) => ({
                url: `/EvaluacionesMetodosRubros/${updateEvaluationRubro.Id}`,
                method: "PUT",
                body: updateEvaluationRubro,
            }),
            invalidatesTags: ["EvaluationRubros"],
        }),
        deleteEvaluationRubro: builder.mutation({
            query: (evaluationRubroId: number) => ({
                url: `/EvaluacionesMetodosRubros/${evaluationRubroId}`,
                method: "DELETE",
                body: evaluationRubroId,
            }),
            invalidatesTags: ["EvaluationRubros"],
        }),
    }),
});

export const {
    useGetEvaluationRubrosQuery,
    useGetEvaluationRubrosByMetodoEvaluacionIdMutation,
    useGetEvaluationRubrosByCapacitacionIdMutation,
    useGetEvaluacionesMetodosRubrosGetAllMutation,
    useCreateEvaluationRubroMutation,
    useUpdateEvaluationRubroMutation,
    useDeleteEvaluationRubroMutation,
} = evaluationRubrosApi;
