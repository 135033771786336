import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const capacitationModulesApi = createApi({
    reducerPath: "capacitationModulesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["CapacitationModules"],
    endpoints: (builder) => ({
        getModules: builder.mutation({
            query: (id) => ({
                url: "/CapacitacionesModulos/GetModulosByCapacitacionPrincipal",
                method: "POST",
                body: { capacitacionId: id },
            }),
            invalidatesTags: ["CapacitationModules"],
        }),
    }),
});

export const { useGetModulesMutation } = capacitationModulesApi;
