import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const rolesApi = createApi({
    reducerPath: "rolesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["Roles"],
    endpoints: (builder) => ({
        getRoles: builder.query({
            query: () => `Roles`,
            providesTags: ["Roles"],
        }),
    }),
});

export const { useGetRolesQuery } = rolesApi;
