import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const teachersApi = createApi({
    reducerPath: "teachersApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["Teachers"],
    endpoints: (builder) => ({
        getTeachersWithSearch: builder.mutation({
            query: (q: string) => ({
                url: "/Docentes/GetDocentes",
                method: "POST",
                body: { q },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Teachers"],
        }),
        getTeachers: builder.query({
            query: () => `/Docentes`,
            providesTags: ["Teachers"],
        }),
        getTeacherById: builder.query({
            query: (id) => `Docentes/${id}`,
            providesTags: ["Teachers"],
        }),
        createTeacher: builder.mutation({
            query: (newTeacher) => ({
                url: "/Docentes",
                method: "POST",
                body: newTeacher,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Teachers"],
        }),
        updateTeacher: builder.mutation({
            query: (updateTeacher) => ({
                url: `/Docentes/${updateTeacher.DocenteId}`,
                method: "PUT",
                body: updateTeacher,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Teachers"],
        }),
        deleteTeacher: builder.mutation({
            query: (teacherId: number) => ({
                url: `/Docentes/${teacherId}`,
                method: "DELETE",
                body: teacherId,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Teachers"],
        }),
        getTeachersByDirector: builder.mutation({
            query: (q: string) => ({
                url: "/Docentes/GetDocentesByDirector",
                method: "POST",
                body: { q },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Teachers"],
        }),
        getTeachersProgress: builder.mutation({
            query: (docenteId: number) => ({
                url: "/Docentes/GetCapacitacionProgreso",
                method: "POST",
                body: { docenteId },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Teachers"],
        }),
        getProgressDocenteByCoordinador: builder.mutation({
            query: (docenteId: number) => ({
                url: "/Docentes/GetCapacitacionProgresoDocenteByCoordinador",
                method: "POST",
                body: { docenteId },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Teachers"],
        }),
        getTeachersProgressByUser: builder.mutation({
            query: () => ({
                url: "/Docentes/GetCapacitacionSeguimiento",
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Teachers"],
        }),
        getTeacherIdByUser: builder.mutation({
            query: () => ({
                url: "/Docentes/GetDocenteByUserId",
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Teachers"],
        }),
        getTeachersCodeAndFullName: builder.mutation({
            query: (escuelasIds: string) => ({
                url: "/Docentes/GetTeachersCodeAndFullName",
                method: "POST",
                body: { escuelasIds: escuelasIds },
            }),
            invalidatesTags: ["Teachers"],
        }),
        getInfoDocenteById: builder.mutation({
            query: (docenteId: number) => ({
                url: "/Docentes/GetInfoDocenteById",
                method: "POST",
                body: { docenteId: docenteId },
            }),
            invalidatesTags: ["Teachers"],
        }),
        getEscuelasDocente: builder.mutation({
            query: (docenteId: number) => ({
                url: "/Docentes/GetEscuelasDocente",
                method: "POST",
                body: { docenteId: docenteId },
            }),
            invalidatesTags: ["Teachers"],
        }),
        getFacultadesDocente: builder.mutation({
            query: (docenteId: number) => ({
                url: "/Docentes/GetFacultadesDocente",
                method: "POST",
                body: { docenteId: docenteId },
            }),
            invalidatesTags: ["Teachers"],
        }),
        getCapacitacionesDocenteByEstado: builder.mutation({
            query: (parameters: { docenteId: number; estadoId: number }) => ({
                url: "/Docentes/GetCapacitacionesDocenteByEstado",
                method: "POST",
                body: parameters,
            }),
            invalidatesTags: ["Teachers"],
        }),
        getTeachersByCordinador: builder.mutation({
            query: (parameters: {
                q: string;
                anio: number;
                periodoId: number;
                sesionId: number;
            }) => ({
                url: "/Docentes/GetDocentesByCoordinadorSesion",
                method: "POST",
                body: parameters,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Teachers"],
        }),
    }),
});

export const {
    useGetTeachersQuery,
    useGetTeacherByIdQuery,
    useCreateTeacherMutation,
    useUpdateTeacherMutation,
    useDeleteTeacherMutation,
    useGetTeachersWithSearchMutation,
    useGetTeachersByDirectorMutation,
    useGetTeachersProgressMutation,
    useGetTeacherIdByUserMutation,
    useGetTeachersProgressByUserMutation,
    useGetTeachersCodeAndFullNameMutation,
    //
    useGetInfoDocenteByIdMutation,
    useGetEscuelasDocenteMutation,
    useGetFacultadesDocenteMutation,
    useGetCapacitacionesDocenteByEstadoMutation,
    //
    useGetTeachersByCordinadorMutation,
    useGetProgressDocenteByCoordinadorMutation,
} = teachersApi;
