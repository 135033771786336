import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

console.log(process.env, "t");
export const certySafeApi = createApi({
    reducerPath: "certySafeApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_CERTYSAFE_ENDPOINT,
    }),
    tagTypes: ["certySafe"],
    endpoints: (builder) => ({
        getTemplates: builder.mutation({
            query: (data) => ({
                url: `PlantillasCertificados/GetAll`,
                body: { ...data },
                method: "POST",
            }),
            invalidatesTags: ["certySafe"],
        }),
        getLink: builder.mutation({
            query: (data) => ({
                url: "CERTIFICATE",
                method: "POST",
                body: { ...data },
            }),
            invalidatesTags: ["certySafe"],
        }),
    }),
});

export const { useGetTemplatesMutation, useGetLinkMutation } = certySafeApi;
