import { createSlice } from "@reduxjs/toolkit";
interface Plantillas {
    capacitations: IPlantillasState;
}
interface IPlantillasState {
    capacitations: [];
}

export const plantillasSlice = createSlice({
    name: "plantillas",
    initialState: {
        plantillas: [],
        plantilla: {},
    },
    reducers: {
        setPlantillas: (state, action) => {
            state.plantillas = action.payload;
        },
        setPlantilla: (state, action) => {
            state.plantilla = action.payload;
        },
    },
});

export const { setPlantillas, setPlantilla } = plantillasSlice.actions;

export const selectCapacitations = (state: Plantillas) => state.capacitations.capacitations;

export default plantillasSlice.reducer;
