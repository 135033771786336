import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const scoresApi = createApi({
    reducerPath: "scoresApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["Scores"],
    endpoints: (builder) => ({
        getScores: builder.query({
            query: () => `CalificacionesTipos`,
            providesTags: ["Scores"],
        }),
        getScoresAndSearch: builder.mutation({
            query: (q) => ({
                url: "/CalificacionesTipos/GetCalificacionesTipos",
                method: "POST",
                body: { q: q },
            }),
            invalidatesTags: ["Scores"],
        }),
        createScore: builder.mutation({
            query: (newScore) => ({
                url: "/CalificacionesTipos",
                method: "POST",
                body: newScore,
            }),
            invalidatesTags: ["Scores"],
        }),
        updateScore: builder.mutation({
            query: (updateScore) => ({
                url: `/CalificacionesTipos/${updateScore.CalificacionId}`,
                method: "PUT",

                body: updateScore,
            }),
            invalidatesTags: ["Scores"],
        }),
        deleteScore: builder.mutation({
            query: (scoreId: number) => ({
                url: `/CalificacionesTipos/${scoreId}`,
                method: "DELETE",
                body: scoreId,
            }),
            invalidatesTags: ["Scores"],
        }),
    }),
});

export const {
    useGetScoresQuery,
    useGetScoresAndSearchMutation,
    useCreateScoreMutation,
    useUpdateScoreMutation,
    useDeleteScoreMutation,
} = scoresApi;
