import { createSlice } from "@reduxjs/toolkit";
interface Scores {
    scores: IScoreState;
}
interface IScoreState {
    scores: [];
}

export const scoresSlice = createSlice({
    name: "scores",
    initialState: {
        scores: [],
        score: {},
    },
    reducers: {
        setScores: (state, action) => {
            state.scores = action.payload;
        },
        setScore: (state, action) => {
            state.score = action.payload;
        },
    },
});

export const { setScores } = scoresSlice.actions;

export const selectScores = (state: Scores) => state.scores.scores;

export default scoresSlice.reducer;
