import React from "react";
import { useNavigate } from "react-router-dom";
import useComponentVisible from "../../hooks/useComponentVisible";
import {
    ContextActionsButtonStyled,
    ContextActionsContainerStyled,
    ContextActionsInputStyled,
    ContextActionsOptionsContainer,
} from "./context-actions.styled";
import { Icon } from "@nubeteck/components";

interface IContextActionsProps {
    searchFunction?: (q: string) => void;
    pathToNew?: string;
    onClick?: () => void;
    btnNew?: string;
    setWayOfDisplayData?: (number: number) => void;
    showAll?: boolean;
    customItems?: JSX.Element;
}
function ContextActions({
    searchFunction,
    pathToNew,
    onClick = () => {
        undefined;
    },
    btnNew,
    setWayOfDisplayData,
    customItems,
}: IContextActionsProps) {
    // const [filtersVisibility, setFiltersVisibility] = useState(false);
    // const [newFilterVisibility, setNewFilterVisibility] = useState(false);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const navigate = useNavigate();

    return (
        <ContextActionsContainerStyled>
            {/* {showAll && (
                <>
                    <ContextActionsButtonStyled
                        onClick={() => setFiltersVisibility(!filtersVisibility)}
                        id="btn-filters"
                    >
                        <Icon name={""} /> Filtros
                    </ContextActionsButtonStyled>
                    <ContextActionsButtonStyled
                        onClick={() => setNewFilterVisibility(!newFilterVisibility)}
                    >
                        <Icon name={""} /> Nuevo filtro
                    </ContextActionsButtonStyled>
                </>
            )} */}

            {searchFunction && (
                <ContextActionsInputStyled
                    type="search"
                    placeholder="Búsqueda..."
                    onChange={(e) => searchFunction(e.target.value)}
                />
            )}
            {btnNew && (
                <ContextActionsButtonStyled
                    onClick={() => {
                        if (pathToNew) navigate(pathToNew);
                        else onClick();
                    }}
                >
                    <Icon name={""} />
                    {btnNew}
                </ContextActionsButtonStyled>
            )}
            {customItems && customItems}

            {setWayOfDisplayData && (
                <>
                    <ContextActionsButtonStyled
                        ref={ref}
                        id="btn-change-view"
                        onClick={() => setIsComponentVisible(!isComponentVisible)}
                    >
                        <Icon name={""} />
                        <Icon name={""} />
                    </ContextActionsButtonStyled>
                    <ContextActionsOptionsContainer isHidden={!isComponentVisible}>
                        <button onClick={() => setWayOfDisplayData(2)}>
                            <Icon name={""} />
                            Lista
                        </button>
                        <button onClick={() => setWayOfDisplayData(1)}>
                            <Icon name={""} />
                            Mosaico
                        </button>
                    </ContextActionsOptionsContainer>
                </>
            )}
        </ContextActionsContainerStyled>
    );
}

export default ContextActions;
