import { IEnrollPrincipalCapacitations } from "@interfaces";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const enrollPrincipalCapacitationsApi = createApi({
    reducerPath: "enrollPrincipalCapacitationsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["Inscriptions"],
    endpoints: (builder) => ({
        getEnrollCapacitations: builder.query({
            query: () => `InscripcionesCapacitacionesPrincipales`,
            providesTags: ["Inscriptions"],
        }),
        enrrollPrincipalCapacitations: builder.mutation({
            query: (InscripcionesCapacitacionesPrincipales: IEnrollPrincipalCapacitations[]) => ({
                url: `/InscripcionesCapacitacionesPrincipales`,
                method: "POST",
                body: { InscripcionesCapacitacionesPrincipales },
            }),
            invalidatesTags: ["Inscriptions"],
        }),
    }),
});

export const { useGetEnrollCapacitationsQuery, useEnrrollPrincipalCapacitationsMutation } =
    enrollPrincipalCapacitationsApi;
