import { createSlice } from "@reduxjs/toolkit";
interface Nationalities {
    nationalities: INationalityState;
}
interface INationalityState {
    nationalities: [];
}

export const nationalitiesSlice = createSlice({
    name: "nationalities",
    initialState: {
        nationalities: [],
        nationality: {},
    },
    reducers: {
        setNationalities: (state, action) => {
            state.nationalities = action.payload;
        },
        setNationality: (state, action) => {
            state.nationality = action.payload;
        },
    },
});

export const { setNationalities } = nationalitiesSlice.actions;

export const selectNationalities = (state: Nationalities) => state.nationalities.nationalities;

export default nationalitiesSlice.reducer;
