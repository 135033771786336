import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAuthState {
    token: string | null;
    refreshToken: string | null;
}

const initialState: IAuthState = {
    token: null,
    refreshToken: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setUser: (state, action: PayloadAction<{ token: string; refreshToken: string }>) => {
            const { token, refreshToken } = action.payload;
            localStorage.setItem("token", token);
            localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
            state.token = token;
            state.refreshToken = refreshToken;
        },
        logOut: (state) => {
            console.log("b");
            state.token = null;
            state.refreshToken = null;
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
        },
    },
});

interface Root {
    auth: IAuthState;
}

export const { setUser, logOut } = authSlice.actions;

export const selectCurrentToken = (state: Root) => state.auth.token;
export const selectCurrentRefreshToken = (state: Root) => state.auth.refreshToken;

export default authSlice.reducer;
