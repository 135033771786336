import { createSlice } from "@reduxjs/toolkit";
interface Faculties {
    faculties: IFacultyState;
}
interface IFacultyState {
    faculties: [];
}

export const facultiesSlice = createSlice({
    name: "faculties",
    initialState: {
        faculties: [],
    },
    reducers: {
        setFaculties: (state, action) => {
            state.faculties = action.payload;
        },
    },
});

export const { setFaculties } = facultiesSlice.actions;

export const selectFaculties = (state: Faculties) => state.faculties.faculties;

export default facultiesSlice.reducer;
