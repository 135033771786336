import { createSlice } from "@reduxjs/toolkit";
interface CapacitationTypes {
    capacitationTypes: ICapacitationTypeState;
}
interface ICapacitationTypeState {
    capacitationTypes: [];
}

export const capacitationTypesSlice = createSlice({
    name: "capacitationTypes",
    initialState: {
        capacitationTypes: [],
    },
    reducers: {
        setCapacitationTypes: (state, action) => {
            state.capacitationTypes = action.payload;
        },
    },
});

export const { setCapacitationTypes } = capacitationTypesSlice.actions;

export const selectCapacitationTypes = (state: CapacitationTypes) =>
    state.capacitationTypes.capacitationTypes;

export default capacitationTypesSlice.reducer;
