import { createSlice } from "@reduxjs/toolkit";
interface StudentsScores {
    studentsScores: IStudentsScoreState;
}
interface IStudentsScoreState {
    studentsScores: [];
}

export const studentsScoresSlice = createSlice({
    name: "studentsScores",
    initialState: {
        studentsScores: [],
        studentsScore: {},
    },
    reducers: {
        setStudentsScores: (state, action) => {
            state.studentsScores = action.payload;
        },
        setStudentsScore: (state, action) => {
            state.studentsScore = action.payload;
        },
    },
});

export const { setStudentsScores } = studentsScoresSlice.actions;

export const selectStudentsScores = (state: StudentsScores) => state.studentsScores.studentsScores;

export default studentsScoresSlice.reducer;
