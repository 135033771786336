import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const dashboardCapacitationsApi = createApi({
    reducerPath: "dashboardCapacitationsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["Capacitations"],
    endpoints: (builder) => ({
        getCapacitationsSesionesAdmin: builder.mutation({
            query: (filtradoPorFacultad: boolean) => ({
                url: "/CapacitacionesSesiones/GetDashboard",
                method: "POST",
                body: { filtradoPorFacultad },
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getCapacitationsSesionesFacilitator: builder.mutation({
            query: () => ({
                url: "/CapacitacionesSesiones/GetByFacilitadorDashboard",
                method: "POST",
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getCapacitationsByPeriodFacilitator: builder.mutation({
            query: () => ({
                url: "/CapacitacionesSesiones/GetByPeriodAndFacilitator",
                method: "POST",
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getCapacitationsByPeriodAdmin: builder.mutation({
            query: (filtradoPorFacultad: boolean) => ({
                url: "/Capacitaciones/GetByPeriod",
                method: "POST",
                body: { filtradoPorFacultad },
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getCapacitationsByStateFacilitator: builder.mutation({
            query: () => ({
                url: "/CapacitacionesSesiones/GetByEstadoFacilitator",
                method: "POST",
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getCapacitationsByStateAdmin: builder.mutation({
            query: (filtradoPorFacultad: boolean) => ({
                url: "/CapacitacionesSesiones/GetByEstado",
                method: "POST",
                body: { filtradoPorFacultad },
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getCapacitationsSesionesDocenteByPeriod: builder.mutation({
            query: (docenteId?: number) => ({
                url: "/CapacitacionesSesiones/GetByPeriod",
                body: { docenteId },
                method: "POST",
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getCapacitationsByStateDocentes: builder.mutation({
            query: (docenteId?: number) => ({
                url: "/CapacitacionesEstudiantes/GetByEstado",
                body: { docenteId },

                method: "POST",
            }),
            invalidatesTags: ["Capacitations"],
        }),
        //Coor -
        getCapacitationsSesionesCoordinador: builder.mutation({
            query: () => ({
                url: "/CapacitacionesSesiones/GetByCoordinadorDashboard",
                method: "POST",
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getCapacitationsByPeriodCoor: builder.mutation({
            query: () => ({
                url: "/CapacitacionesSesiones/GetByPeriodAndCoor",
                method: "POST",
            }),
            invalidatesTags: ["Capacitations"],
        }),
        getCapacitationsByStateCoor: builder.mutation({
            query: () => ({
                url: "/CapacitacionesSesiones/GetByEstadoCoordinador",
                method: "POST",
            }),
            invalidatesTags: ["Capacitations"],
        }),
    }),
});

export const {
    useGetCapacitationsSesionesAdminMutation,
    useGetCapacitationsByPeriodAdminMutation,
    useGetCapacitationsByStateAdminMutation,
    useGetCapacitationsByStateDocentesMutation,
    useGetCapacitationsSesionesDocenteByPeriodMutation,
    useGetCapacitationsSesionesFacilitatorMutation,
    useGetCapacitationsByPeriodFacilitatorMutation,
    useGetCapacitationsByStateFacilitatorMutation,
    useGetCapacitationsSesionesCoordinadorMutation,
    useGetCapacitationsByPeriodCoorMutation,
    useGetCapacitationsByStateCoorMutation,
} = dashboardCapacitationsApi;
