import { createSlice } from "@reduxjs/toolkit";
interface AppIds {
    appIds: IAppIdState;
}
interface IAppIdState {
    appIds: [];
}

export const appIdsSlice = createSlice({
    name: "appIds",
    initialState: {
        appIds: [],
    },
    reducers: {
        setAppIds: (state, action) => {
            state.appIds = action.payload;
        },
    },
});

export const { setAppIds } = appIdsSlice.actions;

export const selectAppIds = (state: AppIds) => state.appIds.appIds;

export default appIdsSlice.reducer;
