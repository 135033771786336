import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const certificateTypesApi = createApi({
    reducerPath: "certificateTypesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["CertificateTypes"],
    endpoints: (builder) => ({
        getCertificateTypes: builder.query({
            query: () => `CertificadosTipos`,
            providesTags: ["CertificateTypes"],
        }),
        getCertificateTypesAndSearch: builder.mutation({
            query: (q) => ({
                url: "/CertificadosTipos/GetCertificadosTipos",
                method: "POST",
                body: { q: q },
            }),
            invalidatesTags: ["CertificateTypes"],
        }),
        createCertificateType: builder.mutation({
            query: (newCertificateType) => ({
                url: "/CertificadosTipos",
                method: "POST",
                body: newCertificateType,
            }),
            invalidatesTags: ["CertificateTypes"],
        }),
        updateCertificateType: builder.mutation({
            query: (updateCertificateType) => ({
                url: `/CertificadosTipos/${updateCertificateType.CertificadoTipoId}`,
                method: "PUT",
                body: updateCertificateType,
            }),
            invalidatesTags: ["CertificateTypes"],
        }),
        deleteCertificateType: builder.mutation({
            query: (certificateTypeId: number) => ({
                url: `/CertificadosTipos/${certificateTypeId}`,
                method: "DELETE",
                body: certificateTypeId,
            }),
            invalidatesTags: ["CertificateTypes"],
        }),
    }),
});

export const {
    useGetCertificateTypesQuery,
    useGetCertificateTypesAndSearchMutation,
    useCreateCertificateTypeMutation,
    useUpdateCertificateTypeMutation,
    useDeleteCertificateTypeMutation,
} = certificateTypesApi;
