import { createSlice } from "@reduxjs/toolkit";

interface ICertySafe {
    templates: [];
}

export const certySafeSlice = createSlice({
    name: "certySafe",
    initialState: {
        templates: [],
    },
    reducers: {
        setTemplates: (state, action) => {
            state.templates = action.payload;
        },
    },
});

export const { setTemplates } = certySafeSlice.actions;

export const selectTemplates = (state: ICertySafe) => state.templates;

export default certySafeSlice.reducer;
