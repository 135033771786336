import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const capacitationTypesApi = createApi({
    reducerPath: "capacitationTypesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["CapacitationTypes"],
    endpoints: (builder) => ({
        getCapacitationTypes: builder.query({
            query: () => `CapacitacionesTipos`,
            providesTags: ["CapacitationTypes"],
        }),
        getCapacitationTypesAndSearch: builder.mutation({
            query: (q) => ({
                url: "/CapacitacionesTipos/GetCapacitacionesTipos",
                method: "POST",
                body: { q: q },
            }),
            invalidatesTags: ["CapacitationTypes"],
        }),
        createCapacitationType: builder.mutation({
            query: (newCapacitationType) => ({
                url: "/CapacitacionesTipos",
                method: "POST",
                body: newCapacitationType,
            }),
            invalidatesTags: ["CapacitationTypes"],
        }),
        updateCapacitationType: builder.mutation({
            query: (updateCapacitationType) => ({
                url: `/CapacitacionesTipos/${updateCapacitationType.CapacitacionTipoId}`,
                method: "PUT",
                body: updateCapacitationType,
            }),
            invalidatesTags: ["CapacitationTypes"],
        }),
        deleteCapacitationType: builder.mutation({
            query: (capacitationTipoId: number) => ({
                url: `/CapacitacionesTipos/${capacitationTipoId}`,
                method: "DELETE",
                body: capacitationTipoId,
            }),
            invalidatesTags: ["CapacitationTypes"],
        }),
    }),
});

export const {
    useGetCapacitationTypesQuery,
    useGetCapacitationTypesAndSearchMutation,
    useCreateCapacitationTypeMutation,
    useUpdateCapacitationTypeMutation,
    useDeleteCapacitationTypeMutation,
} = capacitationTypesApi;
