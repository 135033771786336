import { IPeriodoSeleccion } from "@interfaces";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const selectionsPeriodsApi = createApi({
    reducerPath: "selectionsPeriodsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["SelectionsPeriods"],
    endpoints: (builder) => ({
        getSelectionsPeriods: builder.query({
            query: () => `PeriodosSelecciones`,
            providesTags: ["SelectionsPeriods"],
        }),
        getSelectionPeriodById: builder.query({
            query: (id) => `PeriodosSelecciones/${id}`,
            providesTags: ["SelectionsPeriods"],
        }),
        getAllPeriodsSelections: builder.mutation({
            query: () => ({
                url: "PeriodosSelecciones/GetAll",
                method: "POST",
            }),
            invalidatesTags: ["SelectionsPeriods"],
        }),
        createSelectionPeriod: builder.mutation({
            query: (newSelectionPeriod: IPeriodoSeleccion) => ({
                url: "/PeriodosSelecciones",
                method: "POST",
                body: newSelectionPeriod,
            }),
            invalidatesTags: ["SelectionsPeriods"],
        }),
        updateSelectionPeriod: builder.mutation({
            query: (updateSelectionPeriod: IPeriodoSeleccion) => ({
                url: `/PeriodosSelecciones/${updateSelectionPeriod.PeriodoSeleccionId}`,
                method: "PUT",
                body: updateSelectionPeriod,
            }),
            invalidatesTags: ["SelectionsPeriods"],
        }),
        getPeriodosAbiertos: builder.mutation({
            query: () => ({
                url: `/PeriodosSelecciones/GetPeriodosAbiertos`,
                method: "POST",
            }),
            invalidatesTags: ["SelectionsPeriods"],
        }),
        verifyPeriodoSelection: builder.mutation({
            query: (parameters: { periodoId: number; anio: number }) => ({
                url: `/PeriodosSelecciones/VerifyYearPeriodoSelection`,
                method: "POST",
                body: parameters,
            }),
            invalidatesTags: ["SelectionsPeriods"],
        }),
        getPeriodosByAnio: builder.mutation({
            query: (anio: number) => ({
                url: `/PeriodosSelecciones/GetPeriodosByAnio`,
                method: "POST",
                body: { anio: anio },
            }),
            invalidatesTags: ["SelectionsPeriods"],
        }),
    }),
});

export const {
    useGetSelectionsPeriodsQuery,
    useGetSelectionPeriodByIdQuery,
    useCreateSelectionPeriodMutation,
    useUpdateSelectionPeriodMutation,
    useGetAllPeriodsSelectionsMutation,
    useVerifyPeriodoSelectionMutation,
    useGetPeriodosAbiertosMutation,
    useGetPeriodosByAnioMutation,
} = selectionsPeriodsApi;
