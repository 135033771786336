import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const plantillasApi = createApi({
    reducerPath: "plantillasApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["Plantillas"],
    endpoints: (builder) => ({
        getPlantillas: builder.query({
            query: () => `Plantillas`,
            providesTags: ["Plantillas"],
        }),
        getPlantillaById: builder.query({
            query: (id) => `Plantillas/${id}`,
            providesTags: ["Plantillas"],
        }),
        getTiposPlantillas: builder.query({
            query: () => `plantillasTipos`,
            providesTags: ["Plantillas"],
        }),
        createPlantilla: builder.mutation({
            query: (newTemplate) => ({
                url: "/Plantillas",
                method: "POST",
                body: newTemplate,
            }),
            invalidatesTags: ["Plantillas"],
        }),
        updatePlantilla: builder.mutation({
            query: (updateTemplate) => ({
                url: `/Plantillas/${updateTemplate.PlantillaId}`,
                method: "PUT",
                body: updateTemplate,
            }),
            invalidatesTags: ["Plantillas"],
        }),
    }),
});

export const {
    useGetPlantillaByIdQuery,
    useGetPlantillasQuery,
    useGetTiposPlantillasQuery,
    useCreatePlantillaMutation,
    useUpdatePlantillaMutation,
} = plantillasApi;
