import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const sesionCapacitationsApi = createApi({
    reducerPath: "sesionCapacitationsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["SesionCapacitations"],
    endpoints: (builder) => ({
        getSesionCapacitations: builder.query({
            query: () => `CapacitacionesSesiones`,
            providesTags: ["SesionCapacitations"],
        }),
        getSesionCapacitationById: builder.query({
            query: (id) => `CapacitacionesSesiones/${id}`,
            providesTags: ["SesionCapacitations"],
        }),
        getSesionCapacitationByCapacitationId: builder.mutation({
            query: (CapacitationId: number) => ({
                url: "/CapacitacionesSesiones/GetByCapacitacionId",
                method: "POST",
                body: { CapacitacionId: CapacitationId },
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getSesionesByStudent: builder.mutation({
            query: () => ({
                url: "/CapacitacionesSesiones/GetSesionesByStudent",
                method: "POST",
                body: {},
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getAsignacionModuleByCapacitacionId: builder.mutation({
            query: (CapacitationId: number) => ({
                url: "/CapacitacionesSesiones/GetAsignacionModuleByCapacitacionId",
                method: "POST",
                body: { capacitacionId: CapacitationId },
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getSesionDetail: builder.mutation({
            query: (id: number) => ({
                url: "/CapacitacionesSesiones/GetDetalle",
                method: "POST",
                body: { sesionId: id },
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getSesionDetailAndFacilitador: builder.mutation({
            query: (id: number) => ({
                url: "/CapacitacionesSesiones/GetDetalleBySesionAndFacilitador",
                method: "POST",
                body: { sesionId: id },
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getSessionCapacitationByFacilitator: builder.mutation({
            query: (id: number) => ({
                url: "/CapacitacionesSesiones/GetByFacilitador",
                method: "POST",
                body: { facilitadorId: id },
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        createSesionCapacitation: builder.mutation({
            query: (newSesionCapacitation) => ({
                url: "/CapacitacionesSesiones",
                method: "POST",
                body: newSesionCapacitation,
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        createTeacherSesion: builder.mutation({
            query: (newTeacherSesion) => ({
                url: "/capacitacionesEstudiantes",
                method: "POST",
                body: newTeacherSesion,
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        updateSesionCapacitation: builder.mutation({
            query: (updateSesionCapacitation) => ({
                url: `/CapacitacionesSesiones/${updateSesionCapacitation.SesionId}`,
                method: "PUT",
                body: updateSesionCapacitation,
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        deleteSesionCapacitation: builder.mutation({
            query: (newSesionCapacitation) => ({
                url: "/CapacitacionesSesiones",
                method: "POST",
                body: newSesionCapacitation,
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getPeriodsBySessionCapacitation: builder.mutation({
            query: (id: number) => ({
                url: "/CapacitacionesSesiones/GetCantidadSesionesPorPeriodo",
                method: "POST",
                body: { facilitadorId: id },
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getSessionsAssign: builder.mutation({
            query: () => ({
                url: "/CapacitacionesSesiones/GetAsignacion",
                method: "POST",
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getSessionsAssignByTeacher: builder.mutation({
            query: (periodoId: number) => ({
                url: "/CapacitacionesSesiones/GetAsignacionByTeacher",
                method: "POST",
                body: { periodoId: periodoId },
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getAsignacionByTeacherAndCPId: builder.mutation({
            query: (parameters: {
                periodoId: number;
                capacitacionPrincipalId: number;
                anio: number;
            }) => ({
                url: "/CapacitacionesSesiones/GetAsignacionByTeacherAndCPId",
                method: "POST",
                body: {
                    periodoId: parameters.periodoId,
                    capacitacionPrincipalId: parameters.capacitacionPrincipalId,
                    anio: parameters.anio,
                },
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getPrincipalSessionsAssignByTeacher: builder.mutation({
            query: () => ({
                url: "/Capacitaciones/GetPrincipalCapacitationsBySchool",
                method: "POST",
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getSesionesByDocente: builder.mutation({
            query: () => ({
                url: "/CapacitacionesSesiones/GetSesionesInscritasByDocente",
                method: "POST",
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getAllSesionesSeguimiento: builder.mutation({
            query: (parameters: { Search: string; RowsCount: number; Page: number }) => ({
                url: "/CapacitacionesSesiones/GetAllSesionesSeguimiento",
                method: "POST",
                body: parameters,
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getAllSesionesByFacilitator: builder.mutation({
            query: (parameters: { Search: string; RowsCount: number; Page: number }) => ({
                url: "/CapacitacionesSesiones/GetAllSesionesByFacilitator",
                method: "POST",
                body: parameters,
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getSesionSeguimiento: builder.mutation({
            query: (sesionId: number) => ({
                url: "/CapacitacionesSesiones/GetSesionSeguimiento",
                method: "POST",
                body: { sesionId: sesionId },
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getSesionsByTeacher_AdminView: builder.mutation({
            query: (params: { periodoId: number; docenteId: number }) => ({
                url: "/CapacitacionesSesiones/GetSesionsByTeacher_AdminView",
                method: "POST",
                body: params,
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getSesionsByTeacherAndCPId_AdminView: builder.mutation({
            query: (params: {
                periodoId: number;
                docenteId: number;
                capacitacionPrincipalId: number;
                anio: number;
            }) => ({
                url: "/CapacitacionesSesiones/GetSesionsByTeacherAndCPId_AdminView",
                method: "POST",
                body: params,
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getSesionesCapsSueltas: builder.mutation({
            query: (params: { docenteId: number; periodoId: number; anio: number }) => ({
                url: "/CapacitacionesSesiones/GetCapacitacionesSueltas",
                method: "POST",
                body: params,
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getCapacitacionesSueltasDocenteView: builder.mutation({
            query: (params: { periodoId: number; anio: number }) => ({
                url: "/CapacitacionesSesiones/GetCapacitacionesSueltasDocenteView",
                method: "POST",
                body: params,
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getSesionesByCoordAnioPeriodo: builder.mutation({
            query: (params: { periodoId: number; anio: number }) => ({
                url: "/CapacitacionesSesiones/GetByCordinadorAnioPeriodo",
                method: "POST",
                body: params,
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
        getSesionesByDocenteId: builder.mutation({
            query: (docenteId) => ({
                url: "/CapacitacionesSesiones/ByDocenteId",
                method: "POST",
                body: { docenteId },
            }),
            invalidatesTags: ["SesionCapacitations"],
        }),
    }),
});

export const {
    useGetSesionCapacitationsQuery,
    useGetSesionesByDocenteIdMutation,
    useGetSesionCapacitationByIdQuery,
    useCreateSesionCapacitationMutation,
    useGetSessionCapacitationByFacilitatorMutation,
    useGetSesionCapacitationByCapacitationIdMutation,
    useGetPeriodsBySessionCapacitationMutation,
    useUpdateSesionCapacitationMutation,
    useDeleteSesionCapacitationMutation,
    useCreateTeacherSesionMutation,
    useGetSessionsAssignMutation,
    useGetSesionDetailMutation,
    useGetSessionsAssignByTeacherMutation,
    useGetAsignacionByTeacherAndCPIdMutation,
    useGetPrincipalSessionsAssignByTeacherMutation,
    useGetSesionesByDocenteMutation,
    useGetAsignacionModuleByCapacitacionIdMutation,
    useGetSesionesByStudentMutation,
    useGetAllSesionesSeguimientoMutation,
    useGetSesionsByTeacher_AdminViewMutation,
    useGetSesionsByTeacherAndCPId_AdminViewMutation,
    useGetSesionSeguimientoMutation,
    useGetSesionesCapsSueltasMutation,
    useGetCapacitacionesSueltasDocenteViewMutation,
    useGetAllSesionesByFacilitatorMutation,
    useGetSesionDetailAndFacilitadorMutation,
    useGetSesionesByCoordAnioPeriodoMutation,
} = sesionCapacitationsApi;
