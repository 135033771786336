import { createSlice } from "@reduxjs/toolkit";
interface CertificateTypes {
    certificateTypes: ICertificateTypeState;
}
interface ICertificateTypeState {
    certificateTypes: [];
}

export const certificateTypesSlice = createSlice({
    name: "certificateTypes",
    initialState: {
        certificateTypes: [],
    },
    reducers: {
        setCertificateTypes: (state, action) => {
            state.certificateTypes = action.payload;
        },
    },
});

export const { setCertificateTypes } = certificateTypesSlice.actions;

export const selectCertificateTypes = (state: CertificateTypes) =>
    state.certificateTypes.certificateTypes;

export default certificateTypesSlice.reducer;
