import { createSlice } from "@reduxjs/toolkit";
interface CapacitationModules {
    capacitationModules: ICapacitationModuleState;
}
interface ICapacitationModuleState {
    capacitationModules: [];
}

export const capacitationModulesSlice = createSlice({
    name: "capacitationModules",
    initialState: {
        capacitationModules: [],
    },
    reducers: {
        setCapacitationModules: (state, action) => {
            state.capacitationModules = action.payload;
        },
    },
});

export const { setCapacitationModules } = capacitationModulesSlice.actions;

export const selectCapacitationModules = (state: CapacitationModules) =>
    state.capacitationModules.capacitationModules;

export default capacitationModulesSlice.reducer;
