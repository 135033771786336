import { createSlice } from "@reduxjs/toolkit";
interface TeachersSource {
    teachersSource: ITeacherSourceState;
}
interface ITeacherSourceState {
    teachersSource: [];
    teacherSource: object;
}

export const teachersSourceSlice = createSlice({
    name: "teachersSource",
    initialState: {
        teachersSource: [],
        teacherSource: {},
    },
    reducers: {
        setTeachersSource: (state, action) => {
            state.teachersSource = action.payload;
        },
        setTeacherSource: (state, action) => {
            state.teacherSource = action.payload;
        },
    },
});

export const { setTeachersSource, setTeacherSource } = teachersSourceSlice.actions;

export const selectTeachersSource = (state: TeachersSource) => state.teachersSource.teachersSource;

export default teachersSourceSlice.reducer;
