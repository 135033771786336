import { FormInstance, InputNumber, TableProps, message } from "antd";
import React, { useContext, useRef } from "react";
import { Form, Table } from "antd";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}
const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    console.log(index);
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof any;
    record: any;
    max: number;
    setError: (value: boolean) => void;
    handleSave: (record: any) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    max,
    handleSave,
    ...restProps
}) => {
    const inputRef = useRef<any>(null);
    const form = useContext(EditableContext)!;
    console.log(title);
    const save = async () => {
        try {
            const values = await form.validateFields();
            handleSave({ ...record, ...values });
            restProps.setError(false);
        } catch (errInfo) {
            restProps.setError(true);
            message.error("Corregir calificación");
        }
    };
    let childNode = children;
    if (editable) {
        childNode = (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex as string}
                rules={[
                    {
                        type: "number",
                        max: max,
                        min: 0,
                        message: `Supera el máximo ${max}`,
                    },
                ]}
                initialValue={record[dataIndex] ?? 0}
            >
                <InputNumber ref={inputRef} onChange={save} />
            </Form.Item>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};
type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;
interface TableEditableProps extends TableProps<any> {
    originData: any[];
    columns: any[];
    calculateWay: string;
    onEdit: (data: any) => void;
    setOriginData: (data: any) => void;
    setError: (value: boolean) => void;
}

const TableEditable: React.FC<TableEditableProps> = ({
    onEdit,
    originData,
    columns,
    loading,
    calculateWay,
    setOriginData,
    setError,
}) => {
    const defaultColumns = [
        {
            title: "Código",
            dataIndex: "CodigoEmpleado",
            key: "CodigoEmpleado",
            width: "10%",
        },
        {
            title: "Estudiante",
            dataIndex: "EstudianteNombre",
            key: "EstudianteNombre",
            width: "10%",
        },
        ...columns,
        {
            title: "Total",
            dataIndex: "total",
            render: (_: any, record: any) => {
                console.log(record, "aaaa");
                return (
                    <span>
                        {calculateWay === "Sumatoria" &&
                            Object.keys(record)
                                .filter(
                                    (e: string) =>
                                        ![
                                            "RubroId",
                                            "key",
                                            "EstudianteNombre",
                                            "CapacitacionEstudianteId",
                                            "CodigoEmpleado",
                                        ].find((f) => e == f),
                                )
                                .map((key) => record[key])
                                .reduce((a, b) => a + b, 0)}
                        {calculateWay === "Porcentual" &&
                            Object.keys(record)
                                .filter(
                                    (e: string) =>
                                        ![
                                            "key",
                                            "RubroId",
                                            "EstudianteNombre",
                                            "CapacitacionEstudianteId",
                                            "CodigoEmpleado",
                                        ].find((f) => e == f),
                                )
                                .map((key) => record[key])
                                .reduce((a, b) => a + b, 0)}
                        {calculateWay === "Peso Ponderado" &&
                            Object.keys(record)
                                .filter(
                                    (e: string) =>
                                        ![
                                            "key",
                                            "RubroId",
                                            "EstudianteNombre",
                                            "CapacitacionEstudianteId",
                                            "CodigoEmpleado",
                                        ].find((f) => e == f),
                                )
                                .map((key) => record[key])
                                .reduce((a, b) => a + b, 0)}
                    </span>
                );
            },
        },
    ];

    const handleSave = (row: any) => {
        const newData = [...originData];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setOriginData(newData);
        onEdit(newData);
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const mergedColumns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                editable: col.editable,
                dataIndex: col.title,
                max: col.max,
                title: col.title,
                handleSave,
                setError,
            }),
        };
    });

    return (
        <Table
            loading={loading}
            components={components}
            rowClassName={() => "editable-row"}
            style={{ marginBottom: 10 }}
            bordered
            dataSource={originData}
            columns={mergedColumns as ColumnTypes}
        />
    );
};

export default TableEditable;
