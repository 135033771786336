import { createSlice } from "@reduxjs/toolkit";
interface CapacitationModulesCapacitations {
    capacitationModulesCapacitations: ICapacitationModuleCapacitationState;
}
interface ICapacitationModuleCapacitationState {
    capacitationModulesCapacitations: [];
}

export const capacitationModulesCapacitationsSlice = createSlice({
    name: "capacitationModulesCapacitations",
    initialState: {
        capacitationModulesCapacitations: [],
    },
    reducers: {
        setCapacitationModulesCapacitations: (state, action) => {
            state.capacitationModulesCapacitations = action.payload;
        },
    },
});

export const { setCapacitationModulesCapacitations } =
    capacitationModulesCapacitationsSlice.actions;

export const selectCapacitationModulesCapacitations = (state: CapacitationModulesCapacitations) =>
    state.capacitationModulesCapacitations.capacitationModulesCapacitations;

export default capacitationModulesCapacitationsSlice.reducer;
