import { createSlice } from "@reduxjs/toolkit";
interface SessionCapacitationTypes {
    sessionCapacitationTypes: ISessionCapacitationTypeState;
}
interface ISessionCapacitationTypeState {
    sessionCapacitationTypes: [];
}

export const sessionCapacitationTypesSlice = createSlice({
    name: "sessionCapacitationTypes",
    initialState: {
        sessionCapacitationTypes: [],
    },
    reducers: {
        setSessionCapacitationTypes: (state, action) => {
            state.sessionCapacitationTypes = action.payload;
        },
    },
});

export const { setSessionCapacitationTypes } = sessionCapacitationTypesSlice.actions;

export const selectSessionCapacitationTypes = (state: SessionCapacitationTypes) =>
    state.sessionCapacitationTypes.sessionCapacitationTypes;

export default sessionCapacitationTypesSlice.reducer;
