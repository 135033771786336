import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const capacitationStudentsApi = createApi({
    reducerPath: "capacitationStudentsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["CapacitationStudents"],
    endpoints: (builder) => ({
        getCapacitationStudents: builder.query({
            query: () => `CapacitacionesEstudiantes`,
            providesTags: ["CapacitationStudents"],
        }),
        createCapacitationStudent: builder.mutation({
            query: (parameters: {
                CapacitacionesEstudiantes: any;
                DataCapacitacionPrincipalOficializacion: any | null;
            }) => ({
                url: "/CapacitacionesEstudiantes",
                method: "POST",
                body: parameters,
            }),
            invalidatesTags: ["CapacitationStudents"],
        }),
        updateCapacitationStudent: builder.mutation({
            query: (updateCapacitationStudent) => ({
                url: `/CapacitacionesEstudiantes/${updateCapacitationStudent.CapacitacionEstudianteId}`,
                method: "PUT",
                body: updateCapacitationStudent,
            }),
            invalidatesTags: ["CapacitationStudents"],
        }),
        deleteCapacitationStudent: builder.mutation({
            query: (capacitationStudentId: number) => ({
                url: `/CapacitacionesEstudiantes/${capacitationStudentId}`,
                method: "DELETE",
                body: capacitationStudentId,
            }),
            invalidatesTags: ["CapacitationStudents"],
        }),
        withdrawCapacitationStudent: builder.mutation({
            query: (sesionId: number) => ({
                url: `/CapacitacionesEstudiantes/RetirarSesion`,
                method: "POST",
                body: { sesionId: sesionId },
            }),
            invalidatesTags: ["CapacitationStudents"],
        }),
        withdrawCapacitationStudentV2: builder.mutation({
            query: (sesionIds: string) => ({
                url: `/CapacitacionesEstudiantes/RetirarSesionesV2`,
                method: "POST",
                body: { sesionIds: sesionIds },
            }),
            invalidatesTags: ["CapacitationStudents"],
        }),
        getOficializados: builder.mutation({
            query: (sesionId: number) => ({
                url: `/CapacitacionesEstudiantes/GetOficializados`,
                method: "POST",
                body: { sesionId: sesionId },
            }),
            invalidatesTags: ["CapacitationStudents"],
        }),
        getAllBySesionId: builder.mutation({
            query: (sesionId: number) => ({
                url: `/CapacitacionesEstudiantes/GetAllBySesionId`,
                method: "POST",
                body: { sesionId: sesionId },
            }),
            invalidatesTags: ["CapacitationStudents"],
        }),

        verifyOfficialization: builder.mutation({
            query: (parameters: { estudianteId: number; periodoId: number; anio: number }) => ({
                url: `/CapacitacionesEstudiantes/VerificarOficializacion`,
                method: "POST",
                body: parameters,
            }),
            invalidatesTags: ["CapacitationStudents"],
        }),
    }),
});

export const {
    useGetCapacitationStudentsQuery,
    useCreateCapacitationStudentMutation,
    useUpdateCapacitationStudentMutation,
    useGetAllBySesionIdMutation,
    useDeleteCapacitationStudentMutation,
    useWithdrawCapacitationStudentMutation,
    useWithdrawCapacitationStudentV2Mutation,
    useGetOficializadosMutation,
    useVerifyOfficializationMutation,
} = capacitationStudentsApi;
