import styled from "styled-components";
import {
    Heading,
    Input,
    Text,
    Card as BaseCard,
    Flex,
    ProfileAvatar,
    Icon,
    InputPassword,
    Select,
} from "@nubeteck/components";

import { devices } from "../../constants";

export const CreateUsersContainer = styled(Flex)`
    flex-direction: column-reverse;
    row-gap: 32px;
    margin: 32px 0 64px 0;

    @media ${devices.lg} {
        flex-direction: row;
        align-items: flex-start;
        column-gap: 32px;
    }

    @media ${devices.xl} {
        column-gap: 64px;
    }
`;

export const HeadingStyled = styled(Heading)`
    color: ${({ theme }) => theme.colors.loginHeading} !important;
    margin-bottom: 20px !important;
    font-size: 24px !important;
    @media ${devices.lg} {
        margin-bottom: 40px !important;
    }
`;

export const TextStyled = styled(Text)`
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.primary900};
`;

export const InputStyled = styled(Input)`
    margin-top: 8px;
    border: 1px solid ${({ theme }) => theme.colors.inputBorder};
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    min-width: 130px;
`;

export const IconStyled = styled(Icon)`
    color: ${({ theme }) => theme.colors.colorTextDescription};
`;

export const InputPasswordStyled = styled(InputPassword)`
    margin-top: 8px;
    border: 1px solid ${({ theme }) => theme.colors.inputBorder};
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
`;

export const SelectStyled = styled(Select)`
    display: block;
    margin-top: 8px;
    border-radius: 4px !important;
`;
export const SelectStyledV2 = styled(Select)`
    border-radius: 4px !important;
    width: 100%;
    margin-top: 8px;
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    min-width: 130px;
`;

export const BaseCardStyled = styled(BaseCard)`
    width: 280px;
    min-width: 280px;
    height: fit-content;
    text-align: center;
`;

export const ProfileAvatarStyled = styled(ProfileAvatar)`
    margin-bottom: -16px;
`;

export const BaseCardDesc = styled(Flex)`
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 36px 28px;
    background-color: ${({ theme }) => theme.colors.usersCardDesc};
    backdrop-filter: blur(2px);
    border-radius: 5px;
`;

export const TextDesc = styled(Text)`
    font-weight: 600;
    font-size: 16px;
    line-height: 20.08px;
    color: ${({ theme }) => theme.colors.colorText};
`;

export const CreateUserContainer = styled(Flex)`
    flex-direction: column;
`;
export const CreateUserForm = styled.form`
    flex-direction: column;
`;
export const CreateUserFormRow1 = styled(Flex)`
    flex-direction: column;

    @media ${devices.lg} {
        flex-direction: row;
        column-gap: 16px;
    }

    @media ${devices.xl} {
        flex-direction: row;
        column-gap: 64px;
    }
`;
export const CreateUserFormRow1Column1 = styled(Flex)`
    flex-direction: column;
    row-gap: 16px;
`;
export const CreateUserFormRow1Column2 = styled(Flex)`
    flex-direction: column;
    row-gap: 16px;
    margin: 16px 0;

    @media ${devices.lg} {
        margin: 0;
    }
`;
export const CreateUserFormRow2 = styled(Flex)`
    position: absolute;
    bottom: -64px;
    left: 0;
    right: 0;
    justify-content: center;
    column-gap: 24px;

    @media ${devices.lg} {
        position: relative;
        justify-content: flex-end;
    }
`;
