import { createSlice } from "@reduxjs/toolkit";
interface Logs {
    logs: ILogState;
}
interface ILogState {
    logs: [];
}

export const logsSlice = createSlice({
    name: "logs",
    initialState: {
        logs: [],
    },
    reducers: {
        setLogs: (state, action) => {
            state.logs = action.payload;
        },
    },
});

export const { setLogs } = logsSlice.actions;

export const selectLogs = (state: Logs) => state.logs.logs;

export default logsSlice.reducer;
