import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const generalSettingsApi = createApi({
    reducerPath: "generalSettingsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }),
    tagTypes: ["generalSettings"],
    endpoints: (builder) => ({
        getGeneralSettings: builder.query({
            query: () => `GeneralSettings`,
            providesTags: ["generalSettings"],
        }),
        getAppId: builder.mutation({
            query: () => ({
                url: `AppId/ObtenerDeConfiguracionesGenerales`,
                method: "POST",
            }),
        }),
        updateGeneralSetting: builder.mutation({
            query: (data) => ({
                url: "/GeneralSettings",
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["generalSettings"],
        }),
        defaultGeneralSetting: builder.mutation({
            query: (Clave) => ({
                url: "/GeneralSettingsDefault",
                method: "PUT",
                body: Clave,
            }),
            invalidatesTags: ["generalSettings"],
        }),
    }),
});

export const {
    useGetGeneralSettingsQuery,
    useUpdateGeneralSettingMutation,
    useGetAppIdMutation,
    useDefaultGeneralSettingMutation,
} = generalSettingsApi;
