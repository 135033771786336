import { createSlice } from "@reduxjs/toolkit";
interface Roles {
    roles: IRolesState;
}
interface IRolesState {
    roles: [];
}

export const rolesSlice = createSlice({
    name: "roles",
    initialState: {
        roles: [],
    },
    reducers: {
        setRoles: (state, action) => {
            state.roles = action.payload;
        },
    },
});

export const { setRoles } = rolesSlice.actions;

export const selectRoles = (state: Roles) => state.roles.roles;

export default rolesSlice.reducer;
