import { Navigate } from "react-router-dom";
import React from "react";
import { getRolesByToken } from "src/utils/jwt-util";
import { hasRole } from "src/utils/user-util";

interface IProtectedRoute {
    children: JSX.Element;
    roles: Array<string>;
}
const ProtectedRoute = ({ children, roles }: IProtectedRoute): JSX.Element => {
    const token = localStorage.getItem("token");
    const userRoles = getRolesByToken(token!);

    if (!hasRole(userRoles, roles)) {
        return <Navigate to="/" />;
    }
    return children;
};
export default ProtectedRoute;
