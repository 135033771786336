import styled, { keyframes, css } from "styled-components";
import { Flex, Text } from "@nubeteck/components";

interface StyledContainerProps {
    isSaving?: boolean;
    marginTop?: string;
}

export const LoadingBase = styled(Flex)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    gap: 5px;
`;

export const LoadingText = styled(Text)`
    font-size: 16px;
`;

//Estilos del Loading:
export const StyledSpinContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
`;
export const StyledSpinSubContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;

const blinkAnimation = keyframes`
  0% {
    opacity: 1;
    background-color: #E2A437; /* Color 1 */
  }
  50% {
    opacity: 0;
    background-color: #E0BD83; /* Color 2 */
  }
  100% {
    opacity: 1;
    background-color: #F9F2E6; /* Color 3 */
  }
`;

interface StyledContainerProps {
    isSaving?: boolean;
    marginTop?: string;
}
export const SavingText = styled.span<StyledContainerProps>`=
margin-top: ${({ marginTop }) => marginTop || "10px"};
  animation: ${({ isSaving }) =>
      isSaving
          ? css`
                ${blinkAnimation} 1s infinite
            `
          : "none"};
`;
