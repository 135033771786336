import { IFormUserDataCreate } from "@interfaces";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const usersApi = createApi({
    reducerPath: "usersApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["Users"],
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: () => "Users",
            providesTags: ["Users"],
        }),
        createUser: builder.mutation({
            query: (parameters: {
                UserDataCreate: IFormUserDataCreate;
                EscuelasIds?: number[];
                FacilitadorId?: number;
            }) => ({
                url: "/SignUpWithSchools",
                method: "POST",
                body: parameters,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Users"],
        }),
        updateUser: builder.mutation({
            query: (updateUser) => ({
                url: `/Users/${updateUser.UserId}`,
                method: "PUT",
                body: updateUser,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Users"],
        }),
        changeStateUser: builder.mutation({
            query: (user) => ({
                url: `/Users/${user.UserId}`,
                method: "PUT",
                body: user,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Users"],
        }),
        getUserById: builder.mutation({
            query: () => ({
                url: `/Users/ObtenerPorId`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Users"],
        }),
        getUsersCoordinadores: builder.mutation({
            query: () => ({
                url: `/Users/GetCoordinadores`,
                method: "POST",
                body: {},
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Users"],
        }),
        getUsersAndSearch: builder.mutation({
            query: (q: string) => ({
                url: `/Users/GetUsers`,
                method: "POST",
                body: { q: q },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Users"],
        }),
        changePassword: builder.mutation({
            query: (data: any) => ({
                url: `CHANGEPASSWORD`,
                method: "POST",
                body: data,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Users"],
        }),
        changeState: builder.mutation({
            query: (parameters: { userIdToChange: number; state: number }) => ({
                url: `/Users/ChangeState`,
                method: "POST",
                body: parameters,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Users"],
        }),
        RecoverPassword: builder.mutation({
            query: (data) => ({
                url: `recover/token`,
                method: "POST",
                body: data,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Users"],
        }),
        usersVerificarUserName: builder.mutation({
            query: (userName: string) => ({
                url: `/Users/VerificarUserName`,
                method: "POST",
                body: { UserName: userName },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Users"],
        }),
        usersVerificarEmail: builder.mutation({
            query: (email: string) => ({
                url: `/Users/VerificarEmail`,
                method: "POST",
                body: { Email: email },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            invalidatesTags: ["Users"],
        }),
    }),
});

export const {
    useGetUsersQuery,
    useGetUsersAndSearchMutation,
    useCreateUserMutation,
    useUpdateUserMutation,
    useChangeStateUserMutation,
    useChangeStateMutation,
    useGetUserByIdMutation,
    useChangePasswordMutation,
    useRecoverPasswordMutation,
    useGetUsersCoordinadoresMutation,
    useUsersVerificarEmailMutation,
    useUsersVerificarUserNameMutation,
} = usersApi;
