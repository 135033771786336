import React from "react";

export default function useComponentVisible(IsVisible: boolean) {
    const [isComponentVisible, setIsComponentVisible] = React.useState(IsVisible);
    const ref = React.useRef<HTMLButtonElement>(null);

    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    return { ref, isComponentVisible, setIsComponentVisible };
}
