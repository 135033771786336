import { createSlice } from "@reduxjs/toolkit";
interface EvaluationRubros {
    evaluationRubros: IEvaluationRubroState;
}
interface IEvaluationRubroState {
    evaluationRubros: [];
}

export const evaluationRubrosSlice = createSlice({
    name: "evaluationRubros",
    initialState: {
        evaluationRubros: [],
    },
    reducers: {
        setEvaluationRubros: (state, action) => {
            state.evaluationRubros = action.payload;
        },
    },
});

export const { setEvaluationRubros } = evaluationRubrosSlice.actions;

export const selectEvaluationRubros = (state: EvaluationRubros) =>
    state.evaluationRubros.evaluationRubros;

export default evaluationRubrosSlice.reducer;
