import { createSlice } from "@reduxjs/toolkit";
interface AcademicLevels {
    academicLevels: IAcademicLevels;
}
interface IAcademicLevels {
    academicLevels: [];
}

export const academicLevelsSlice = createSlice({
    name: "academicLevels",
    initialState: {
        academicLevels: [],
    },
    reducers: {
        setAcademicLevels: (state, action) => {
            state.academicLevels = action.payload;
        },
    },
});

export const { setAcademicLevels } = academicLevelsSlice.actions;

export const selectAcademicLevels = (state: AcademicLevels) => state.academicLevels.academicLevels;

export default academicLevelsSlice.reducer;
